<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        outlined
        hide-details
      ></v-text-field>
    </v-card-title>
    <WarningDialog
      ref="warning"
      @warningActionPerformed="warningActionPerformed"
    />
    <v-dialog v-model="warningDialog" width="650">
      <v-card>
        <v-toolbar color="warning" dark>
          <v-icon @click="warningDialog = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center">
            Warnung
          </v-toolbar-title>
        </v-toolbar>
        <v-container class="pa-4">
          <v-row class="mx-2">
            <v-col cols="12">
              <v-card-text>
                {{ warnMessage }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error lighten-1 px-8" @click="deleteItemConfirm()"
                  >LÖSCHEN</v-btn
                >
                <v-btn color="black" text @click="warningDialog = false"
                  >Abbrechen</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="d-flex flex-column align-end"
              cols="colsPerButton"
              v-for="action in actions"
              :key="action.action"
            >
              <v-btn
                outlined
                class="warning--text"
                @click="warningActionPerformed(action.action)"
              >
                <v-icon class="mr-1">{{ action.icon }}</v-icon>
                {{ action.text }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredContacts"
      :items-per-page="10"
      :item-class="formatContactPersonActive"
      :search="search"
      item-key="key.kdnr + key.lfdnr"
      class="elevation-1"
      :loading="loading"
      sort-by="nachname"
      :sort-desc="sortDesc"
      mobile-breakpoint="800"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-container class="fill-height" fluid>
            <v-switch
              label="Inaktive Ansprechpartner anzeigen"
              v-model="showInactiveContactPersons"
            />
          </v-container>
          <v-dialog
            v-model="showContact"
            scrollable
            max-width="900px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click=";(newContact = true), show({})"
              >
                Neue Kontaktperson
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="mx-0 px-0 my-0 py-0">
                <v-toolbar
                  color="primary"
                  class="mx-0 px-0 my-0 py-0"
                  fixed
                  top
                  dark
                >
                  <v-icon @click="showContact = false">mdi-close</v-icon>

                  <v-toolbar-title class="headline flex text-center"
                    >{{ activeContact.vorname }}
                    {{ activeContact.nachname }}</v-toolbar-title
                  >
                </v-toolbar>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <p class="py-4 mt-3">Persönliche Daten</p>
                  <v-row>
                    <v-col class="pa-0 pr-1" cols="12" lg="2">
                      <v-text-field
                        label="Titel"
                        v-model="activeContact.titel"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 pr-1" cols="12" lg="5">
                      <v-text-field
                        label="Vorname"
                        v-model="activeContact.vorname"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 pr-1" cols="12" lg="5">
                      <v-text-field
                        label="Nachname"
                        v-model="activeContact.nachname"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- <v-col class="pa-0 pr-1" cols="6">
                      <v-text-field
                        v-model="activeContact.geburtsdatum"
                        type="date"
                        label="Geburtsdatum"
                        :rules="[birthDataValidationRule]"
                        outlined
                        validate-on-blur
                      ></v-text-field>
                    </v-col> -->
                    <v-col class="pa-0 pr-1" cols="12" lg="4">
                      <v-select
                        label="Geschlecht"
                        v-model="activeContact.geschlecht"
                        :items="genders"
                        item-text="bezeichnung"
                        return-object
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col class="pa-0 pr-1" cols="12" lg="4">
                      <v-autocomplete
                        label="Funktion"
                        :items="functions"
                        item-text="name"
                        item-value="lfdnr"
                        v-model="activeContact.funktionen"
                        counter="255"
                        maxlength="255"
                        @input.native="
                          e => (activeContact.funktion = e.target.value)
                        "
                        :filter="functionComboBoxFilter"
                        return-object
                        outlined
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pa-0 pr-1" cols="12" lg="4">
                      <v-autocomplete
                        label="Abteilung"
                        :items="departments"
                        item-text="name"
                        item-value="lfdnr"
                        v-model="activeContact.abteilungen"
                        counter="255"
                        maxlength="255"
                        @input.native="
                          e => (activeContact.department = e.target.value)
                        "
                        return-object
                        outlined
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <p class="py-4 mt-3">Kontaktdaten</p>
                  <v-row>
                    <v-col class="pa-0 pr-1" cols="12" lg="5">
                      <v-text-field
                        label="Mobiltelefon"
                        v-model="activeContact.tel_mobil"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col class="pa-0 pr-1" cols="12" lg="5">
                      <v-text-field
                        label="Telefon2"
                        v-model="activeContact.tel2"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 pr-1" cols="12" lg="2">
                      <v-text-field
                        label="DW"
                        v-model="activeContact.dw"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pa-0 pr-1" cols="12" sm="6" lg="6">
                      <v-text-field
                        label="E-Mail"
                        v-model="activeContact.email"
                        outlined
                      ></v-text-field> </v-col
                    ><v-col class="pa-0 pr-1" cols="12" sm="6" lg="6">
                      <v-text-field
                        label="Fax"
                        v-model="activeContact.fax"
                        outlined
                      ></v-text-field> </v-col
                  ></v-row>

                  <v-row>
                    <v-textarea
                      label="Anmerkung"
                      v-model="activeContact.anmerkung"
                      outlined
                    ></v-textarea
                  ></v-row>

                  <v-row class="pa-0">
                    <v-checkbox
                      label="Aktiv"
                      v-model="activeContact.active"
                      outlined
                    ></v-checkbox>
                    <v-checkbox
                      label="Hauptansprechpartner"
                      v-model="activeContact.hauptansprechpartner"
                      outlined
                      class="ml-12"
                    ></v-checkbox>
                    <v-checkbox
                      label="Aussendung"
                      v-model="activeContact.aussendung"
                      outlined
                      class="ml-10"
                    ></v-checkbox>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="newContact == true"
                  color="success"
                  @click="save('new')"
                >
                  Speichern
                </v-btn>
                <v-btn v-else color="success" @click="save('existing')">
                  Speichern
                </v-btn>
                <v-btn color="error" text @click="cancel(), close()">
                  Abbrechen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.abteilungen="{ item }">
        {{ item.abteilungen.map(department => department.name).join(', ') }}
      </template>
      <template v-slot:item.funktionen="{ item }">
        {{ item.funktionen.map(funktion => funktion.name).join(', ') }}
      </template>
      <template v-slot:item.hauptansprechpartner="{ item }">
        <v-simple-checkbox
          v-model="item.hauptansprechpartner"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          fab
          small
          text
          elevation="2"
          class="mr-2 col-xl-5"
          @click=";(newCustomerGroup = false), show(item)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          text
          elevation="2"
          color="red"
          class="mr-2 col-xl-5"
          @click=";(deleteContactPerson = item), (warningDialog = true)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template> Keine Kontaktpersonen vorhanden </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import WarningDialog from '@/components/core/WarningDialog.vue'
// https://techformist.com/vuetify-input-field-date-picker/
// filters: https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component

export default {
  components: {
    WarningDialog
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      //TODO: getFunctions
      showInactiveContactPersons: false,
      menu: false,
      activeContact: [],
      deleteContactDialog: false,
      newContact: false,
      oldContact: {},
      showContact: false,
      loading: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      sortDesc: true,
      editorContent: '',
      addedContact: {},
      updatedContact: {},
      deleteContact: {},
      warningDialog: false,
      warnMessage: 'Wollen Sie diesen Ansprechpartner löschen?',
      actions: [],
      colsPerButton: 0,
      additionalData: [],

      dialogInputMethods: ['Manuell', 'E-Mail', 'Telefon'],
      headers: [
        {
          text: 'Vorname',
          align: 'start',
          value: 'vorname'
        },
        {
          text: 'Nachname',
          value: 'nachname'
        },
        { text: 'Abteilung', value: 'abteilungen' },
        { text: 'Funktion', value: 'funktionen' },
        { text: 'E-Mail', value: 'email' },
        { text: 'Telefon mobil', value: 'tel_mobil' },
        { text: 'Telefon 2', value: 'tel2' },
        { text: 'Durchwahl', value: 'dw' },
        { text: 'Haupt', value: 'hauptansprechpartner' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '12em'
        }
      ],

      editedIndex: -1,
      editedItem: {}
      // birthDataValidationRule: value => {
      //   const pattern = /^\d{4}-\d{2}-\d{2}$/
      //   return pattern.test(value) || 'Ungültiges Geburtsdatum'
      // }
    }
  },
  computed: {
    ...mapState({
      contacts: state => state.crmContacts.contacts,
      genders: state => state.crmContacts.genders,
      functions: state => state.crmContacts.functions,
      departments: state => state.crmContacts.departments
    }),
    filteredContacts() {
      if (this.showInactiveContactPersons) {
        return this.contacts
      }
      return this.contacts.filter(c => c.active)
    }
  },
  methods: {
    init() {
      this.loadContacts()
      this.getGenders()
      this.getFunktionen()
      this.loadDepartments()
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true
      }
      return false
    },
    deleteItemConfirm() {
      this.remove(this.deleteContactPerson)
      this.warningDialog = false
      this.deleteContactPerson = null
    },
    open(warnMessage, actions, additionalData = []) {
      this.warnMessage = warnMessage
      this.actions = actions
      this.warningDialog = true
      this.colsPerButton = Math.floor(12 / actions.length)
      this.additionalData = additionalData
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action)
      this.warningDialog = false
    },
    loadContacts() {
      this.$store
        .dispatch('loadContacts', {
          lfdnr: this.lfdnr
        })
        .then((this.loading = false))
    },

    getGenders() {
      this.$store.dispatch('getGenders')
    },

    getFunktionen() {
      this.$store.dispatch('getFunktionen')
    },

    loadDepartments() {
      this.$store.dispatch('getDepartments')
    },

    cancel() {
      this.activeContact = { ...this.oldContact }
    },
    close() {
      this.showContact = false
    },
    save(x) {
      //Wenn Benutzer kein Geschlecht angibt, dann wird geschlecht auf "ohne" gesetzt
      if (this.activeContact.geschlecht == null) {
        this.activeContact.geschlecht = this.genders.filter(obj => {
          return obj.key.lfdnr === 0
        })[0]
      }

      // if (this.activeContact.geburtsdatum != null) {
      //   if (this.activeContact.geburtsdatum.startsWith('000'))
      //     this.activeContact.geburtsdatum =
      //       '200' + this.activeContact.geburtsdatum.substring(3)
      //   else if (this.activeContact.geburtsdatum.startsWith('00'))
      //     this.activeContact.geburtsdatum =
      //       '19' + this.activeContact.geburtsdatum.substring(2)
      // }

      //Wenn neuer Kontakt angelegt werden soll
      if (x == 'new') {
        if (this.activeContact.active == false) {
          this.activeContact.active = 0
        } else {
          this.activeContact.active = 1
        }

        this.addedContact = {
          key: { firma: 1, kdnr: this.lfdnr, lfdnr: 1 },
          titel: this.activeContact.titel,
          vorname: this.activeContact.vorname,
          nachname: this.activeContact.nachname,
          dw: this.activeContact.dw,
          geschlecht: this.activeContact.geschlecht.key.lfdnr,
          funktionen: this.activeContact.funktionen,
          email: this.activeContact.email,
          geburtsdatum: this.activeContact.geburtsdatum,
          tel_mobil: this.activeContact.tel_mobil,
          aussendung: this.activeContact.aussendung,
          active: this.activeContact.active,
          anmerkung: this.activeContact.anmerkung,
          hauptansprechpartner: this.activeContact.hauptansprechpartner,
          tel2: this.activeContact.tel2,
          fax: this.activeContact.fax,
          abteilungen: this.activeContact.abteilungen
        }

        this.$store
          .dispatch('saveContact', {
            lfdnr: this.lfdnr,
            wf_cid: this.wf_cid,
            contact: this.addedContact
          })
          .then(() => {
            this.loading = false
          })

        //Wenn existierender Kontakt bearbeitet werden soll
      } else {
        this.updatedContact = {
          key: this.activeContact.key,
          titel: this.activeContact.titel,
          vorname: this.activeContact.vorname,
          nachname: this.activeContact.nachname,
          dw: this.activeContact.dw,
          geschlecht: this.activeContact.geschlecht.key.lfdnr,
          funktionen: this.activeContact.funktionen,
          email: this.activeContact.email,
          geburtsdatum: this.activeContact.geburtsdatum,
          tel_mobil: this.activeContact.tel_mobil,
          aussendung: this.activeContact.aussendung,
          active: this.activeContact.active == true ? 1 : 0,
          anmerkung: this.activeContact.anmerkung,
          hauptansprechpartner: this.activeContact.hauptansprechpartner,
          tel2: this.activeContact.tel2,
          fax: this.activeContact.fax,
          abteilungen: this.activeContact.abteilungen
        }

        this.$store
          .dispatch('updateContact', {
            lfdnr: this.lfdnr,
            wf_cid: this.wf_cid,
            contact: this.updatedContact
          })
          .then(() => {
            this.loading = false
          })
      }

      this.showContact = false
    },
    show(item) {
      if (this.newContact == true) {
        this.activeContact = {
          active: true
        }
      } else {
        this.activeContact = { ...item }
        // Date fix in showing (only displaying date in YYYY-MM-DD format)
        if (this.activeContact.geburtsdatum != null) {
          this.activeContact.geburtsdatum = new Date(
            this.activeContact.geburtsdatum
          )
            .toISOString()
            .split('T')[0]
        }

        //get Gender of active Contact
        this.activeContact.geschlecht = this.genders.filter(obj => {
          return obj.key.lfdnr === this.activeContact.geschlecht
        })[0]

        this.activeContact.department = this.activeContact.department?.department

        //Kontakt vor Änderungen
        this.oldContact = Object.assign({}, this.activeContact)
      }

      this.showContact = true
    },

    remove(item) {
      this.$store
        .dispatch('deleteContact', { lfdnr: this.lfdnr, key: item.key })
        .then(() => {
          this.loadContacts()
          this.deleteContactDialog = false
        })
    },

    functionComboBoxFilter(item, queryText, itemText) {
      if (item.header) return false

      const text = itemText == null ? '' : itemText
      const query = queryText == null ? '' : queryText
      if (query.startsWith('*')) {
        const editedQuery = query.toString().replace('*', '')
        return (
          text
            .toString()
            .toLowerCase()
            .indexOf(editedQuery.toString().toLowerCase()) > -1
        )
      } else {
        return text
          .toString()
          .toLowerCase()
          .startsWith(query.toString().toLowerCase())
      }
    },

    formatContactPersonActive(item) {
      if (item != null && false == item.active) {
        return 'inactive-row font-italic'
      }
    }
  },
  watch: {
    lfdnr() {
      this.init()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style>
.inactive-row {
  background-color: #ffcdd2;
}
</style>
