<template>
  <v-dialog :value="true" scrollable max-width="500px" persistent>
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar color="primary" class="mx-0 px-0 my-0 py-0" fixed top dark>
          <v-icon @click="cancel">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center"
            >Kontaktdaten bearbeiten</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <h3 class="mt-8 mb-3">Telefon</h3>
        <v-text-field
          v-model="modificationAddress.tel"
          label="Telefon"
          append-icon="mdi-phone"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="modificationAddress.tel2"
          label="Telefon 2"
          append-icon="mdi-phone-outline"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="modificationAddress.mobile"
          label="Mobil"
          append-icon="mdi-cellphone"
          outlined
        ></v-text-field>
        <h3 class="mt-5 mb-3">E-Mail</h3>
        <v-text-field
          v-model="modificationAddress.email"
          label="E-Mail"
          append-icon="mdi-email"
          outlined
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save" :loading="saving">
          Speichern
        </v-btn>
        <v-btn color="error" text @click="cancel"> Abbrechen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import addressService from '@/services/crm/addressService.js'

export default {
  props: {
    address: {
      required: true
    }
  },
  data() {
    return {
      modificationAddress: {
        tel: null,
        tel2: null,
        mobile: null,
        email: null
      },
      saving: false
    }
  },
  methods: {
    cancel() {
      this.$emit('closeDialog')
    },
    save() {
      const viewModel = this
      this.saving = true

      addressService
        .updateContactData(this.modificationAddress.key.lfdnr, {
          tel1: this.modificationAddress.tel,
          tel2: this.modificationAddress.tel2,
          mobile: this.modificationAddress.mobile,
          email: this.modificationAddress.email
        })
        .then(() => {
          this.saving = false
          viewModel.$emit('saved', viewModel.modificationAddress)
          viewModel.$emit('closeDialog')
        })
        .catch(err => {
          this.saving = false
          viewModel.$emit('error', err)
        })
    }
  },
  mounted() {
    if (this.address != null) {
      this.modificationAddress = { ...this.address }
    }
  }
}
</script>

<style></style>
