<template>
  <div class="informationTabAlign">
    <v-form>
      <v-row>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-text-field
            label="HV-Nr."
            outlined
            append-icon="mdi-numeric"
            v-model="address.doctor.hvnr"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="8" xl="8" md="8">
          <v-text-field
            label="Fachrichtung"
            outlined
            prepend-inner-icon="mdi-stethoscope"
            v-model="address.doctor.subjectName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addressService from '@/services/crm/addressService.js'
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson
    })
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    address: null
  }),
  methods: {
    loadaddress() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response)
        this.address = response.data
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    }
  }
}
</script>

<style>
.informationTabAlign {
  margin: 4vh 2vw;
}
</style>
