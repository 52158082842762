<template>
  <div class="alignInformationTab">
    <v-form>
      <v-row>
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            label="Verband"
            outlined
            prepend-inner-icon="mdi-account-group"
            v-model="address.healthInsuranceCompany.association"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            label="Tarifgruppe"
            outlined
            prepend-inner-icon="mdi-account-cash"
            v-model="address.healthInsuranceCompany.wageGroupName"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            label="Fibugruppe"
            outlined
            prepend-inner-icon="mdi-account-group"
            v-model="address.healthInsuranceCompany.fibuGroupName"
            disabled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3" md="3" xl="3">
          <v-text-field
            outlined
            label="Kundennummer Warnwirtschaft"
            append-icon="mdi-numeric"
            v-model="address.healthInsuranceCompany.kdNr"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" xl="3">
          <v-text-field
            outlined
            label="Vertragspartnernummer"
            append-icon="mdi-handshake"
          ></v-text-field>
          <!-- TODO: missing in model -- named contractPartnerNumber-->
        </v-col>
        <v-col cols="12" lg="3" md="3" xl="3">
          <v-text-field
            outlined
            label="UID"
            append-icon="mdi-identifier"
            v-model="address.healthInsuranceCompany.uid"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <h2 class="overline font-weight-black mt-3">Abrechnung</h2>
      <v-divider />

      <v-row class="mt-3">
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-text-field
            outlined
            label="Leistungszuständiger Versicherungsträger"
            prepend-inner-icon="mdi-account-tie"
            v-model="
              address.healthInsuranceCompany.accounting
                .serviceInsuranceCarrierName
            "
            disabled
          ></v-text-field>
          <!-- TODO: fehlt in options-->
        </v-col>
        <v-col cols="12" lg="2" xl="2" md="2">
          <v-text-field
            outlined
            label="Bundesland"
            prepend-inner-icon="mdi-map-legend"
            v-model="address.healthInsuranceCompany.accounting.serviceState"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            label="Datenzuständiger Versicherungsträger"
            prepend-inner-icon="mdi-account-tie"
            v-model="
              address.healthInsuranceCompany.accounting.dataInsuranceCarrierName
            "
            disabled
          ></v-text-field>
          <!-- TODO: fehlt in options-->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4" md="4" xl="4">
          <v-text-field
            outlined
            label="Abrechnungszuständiger Versicherungsträger"
            prepend-inner-icon="mdi-account-tie"
            v-model="
              address.healthInsuranceCompany.accounting
                .accountingInsuranceCarrierName
            "
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="2" md="2">
          <v-autocomplete
            outlined
            label="Bundesland"
            prepend-inner-icon="mdi-map-legend"
            v-model="address.healthInsuranceCompany.accounting.accountingState"
            disabled
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-checkbox
            label="Fremdrechnungsnummer"
            class="disabledCheckbox"
            disabled
            v-model="
              address.healthInsuranceCompany.accounting.externalInvoiceNumber
            "
          >
          </v-checkbox>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-checkbox
            label="Selbstbehalt-Rechnungstrennung"
            v-model="
              address.healthInsuranceCompany.accounting.excessInvoiceSeparation
            "
            disabled
          >
          </v-checkbox>
        </v-col>
      </v-row>

      <h2 class="overline font-weight-black mt-3">Dokumentsteuerung</h2>
      <v-divider />

      <v-row class="mt-3">
        <v-col cols="12" lg="2" xl="2" md="2">
          <v-text-field
            outlined
            label="Sammelr. Int."
            suffix="Tage"
            v-model="
              address.healthInsuranceCompany.documentControl
                .collectiveInvoiceInterval
            "
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="5" xl="5" md="5">
          <v-text-field
            outlined
            label="Sammelrechnungstyp"
            prepend-inner-icon="mdi-receipt-text-outline"
            v-model="
              address.healthInsuranceCompany.documentControl
                .collectiveInvoiceType
            "
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="5" xl="5" md="5">
          <v-text-field
            outlined
            label="Sammelrechnungsdeckblatt"
            prepend-inner-icon="mdi-receipt-text"
            v-model="
              address.healthInsuranceCompany.documentControl
                .collectiveInvoiceCoverSheet
            "
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-text-field
            label="Eigenanteilsgruppe"
            outlined
            v-model="
              address.healthInsuranceCompany.documentControl
                .ownContributionGroup
            "
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Datenträger"
            v-model="address.healthInsuranceCompany.documentControl.dataCarrier"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Bruttopreise"
            v-model="address.healthInsuranceCompany.documentControl.grossPrices"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Gruppenrechnung"
            v-model="
              address.healthInsuranceCompany.documentControl.groupInvoice
            "
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Selbstbehalt"
            v-model="address.healthInsuranceCompany.documentControl.excess"
            disabled
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Nulltarifautomatik"
            v-model="address.healthInsuranceCompany.autoFreeAdmission"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Bundeslandcheck"
            v-model="address.healthInsuranceCompany.documentControl.checkState"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="8" md="8" xl="8">
          <v-checkbox
            label="Pauschale-Überschreitung Code"
            v-model="
              address.healthInsuranceCompany.documentControl.lumSumExcessCode
            "
            disabled
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addressService from '@/services/crm/addressService.js'
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,

      options: state => state.crmAddresses.addressOptions
    })
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    address: null
  }),
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response)
        this.address = response.data
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    }
  }
}
</script>

<style>
.alignInformationTab {
  margin: 4vh 2vw;
}
</style>
