<template>
  <div
    class="my-5 grey lighten-4 elevation-2 rounded-pill mx-2"
    style="font-size: 20px; hyphens: auto; width: fit-content; padding: 5px;"
  >
    <v-avatar size="34" color="white" class="mr-2 elevation-2">
      <v-icon>{{ icon }}</v-icon>
    </v-avatar>
    {{ name }}
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    }
  }
}
</script>
