<template>
  <div>
    <v-container max-width="100%" v-if="addressExists == 'true'">
      <!--<SiteHeaderComponent :header="address.name" />-->
      <v-card :loading="loading" class="mx-auto my-12">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <ContactDataDialog
          v-if="editContactData"
          :address="address"
          @closeDialog="editContactData = false"
          @saved="updateContactData"
          @error="
            this.failMessage =
              'Beim Speichern der Kontaktdaten ist ein unerwarteter Fehler aufgetreten!'
            this.errorDialog = true
          "
        />

        <AddressPicturesDialog
          v-if="showAddressPicturesDialog"
          @close-dialog="closeAddressPicturesDialog()"
          :address="address"
          :opened="true"
        >
        </AddressPicturesDialog>

        <l-map
          style="width: 100%; height: 350px; z-index: 1"
          :zoom="17.5"
          :center="mapsAddressObject"
          :options="mapOptions"
          v-if="showMap"
        >
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-marker :lat-lng="mapsAddressObject">
            <l-popup>
              <div>
                <b>{{ address.name }}</b
                ><br />
                {{ address.address.street }}<br />
                {{ address.city }} {{ address.postcode }}
              </div>
            </l-popup>
          </l-marker>
        </l-map>

        <v-card-title
          class="ml-4 mt-4 mb-n6 d-flex justify-space-between"
          lang="de"
          style="font-size: 3.5vh"
        >
          <h4
            class="mr-8 font-weight-bold text-upercase text-break"
            style="word-wrap: break-word; hyphens: auto"
            v-if="isMobile()"
          >
            <v-btn
              color="primary"
              fab
              dark
              class="ml-2"
              @click="showAddressPicturesDialog = true"
              ><v-icon>mdi-image-multiple</v-icon></v-btn
            >
          </h4>
          <h4 class="mr-8 font-weight-bold text-upercase text-break" v-else>
            <span
              v-if="
                address.firstname == undefined && address.lastname == undefined
              "
              >{{ address.name }}</span
            >
            <span v-else>{{ address.firstname + ' ' + address.lastname }}</span>
            <v-btn
              color="primary"
              @click="showAddressPicturesDialog = true"
              large
              icon
              class="ml-3 mt-n1"
              elevation="1"
              ><v-icon>mdi-image-multiple</v-icon></v-btn
            >
          </h4>
          <div
            :class="
              isMobile() ? 'col col-sm-12 col-lg-4 col-xl-3' : 'd-inline-flex'
            "
          >
            <div v-for="(role, index) in allRoles" :key="index">
              <AddressTypeChip :name="role.name" :icon="role.icon" />
            </div>
          </div>
        </v-card-title>
        <v-card-text class="d-flex justify-space-between flex-wrap">
          <div class="elevation-0 pa-4 mb-4 col-md-6 col-12">
            <h3 class="mb-4 text-h6">Anschrift</h3>
            <div class="body-1 mb-4">
              <v-row class="ml-0">
                <v-col class="pa-0 col-4"> Straße: </v-col>
                <v-col class="pa-0">
                  {{ address.address.street }}
                </v-col>
              </v-row>
              <v-row class="ml-0">
                <v-col class="pa-0 col-4"> Ort: </v-col>
                <v-col class="pa-0">
                  {{ address.address.zip }} {{ address.address.city }}
                </v-col>
              </v-row>
              <v-row class="ml-0">
                <v-col class="pa-0 col-4"> Land: </v-col>
                <v-col class="pa-0">
                  <span>{{ address.address.countryName }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-lg-4 pl-2 col-12"
                  ><v-btn
                    outlined
                    @click="openAddressInGoogleMaps"
                    class="col-12"
                  >
                    <v-icon class="mr-2">mdi-google-maps</v-icon>
                    Google Maps
                  </v-btn></v-col
                >
                <v-col class="col-lg-4 pl-2 col-12"
                  ><v-btn
                    outlined
                    @click="openAddressInGoogleMapsWithNavigation"
                    class="col-12"
                  >
                    <v-icon class="mr-2">mdi-navigation</v-icon>
                    Navigation
                  </v-btn></v-col
                >
              </v-row>
            </div>
          </div>
          <div class="elevation-0 pa-4 mb-4 col-md-6 col-12">
            <h3 class="mb-4 text-h6">Kontaktdaten</h3>
            <div class="body-1 mb-4">
              <v-row class="ml-0">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-phone</v-icon
                  >
                  Telefon:
                </v-col>
                <v-col class="pa-0">
                  <a :href="telLink">{{ address.tel }}</a>
                </v-col>
              </v-row>
              <v-row class="ml-0 mt-4">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-phone-outline</v-icon
                  >
                  Telefon 2:
                </v-col>
                <v-col class="pa-0">
                  <a :href="telLink">{{ address.tel2 }}</a>
                </v-col>
              </v-row>
              <v-row class="ml-0 mt-4">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-cellphone</v-icon
                  >
                  Mobil:
                </v-col>
                <v-col class="pa-0">
                  <a :href="telLink">
                    {{ address.mobile }}
                  </a>
                </v-col>
              </v-row>
              <v-row class="ml-0 mt-6">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-email</v-icon
                  >
                  E-Mail:
                </v-col>
                <v-col class="pa-0">
                  <a :href="mailLink"> {{ address.email }}</a>
                </v-col>
              </v-row>
              <v-row
                ><v-btn
                  class="mt-5 ml-2"
                  outlined
                  @click="editContactData = true"
                  >Bearbeiten</v-btn
                ></v-row
              >
            </div>
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>
        <div class="tabWidthFill">
          <v-tabs grow v-model="tab">
            <v-tab
              href="#patientInformation"
              v-if="address.patient !== undefined && address.patient !== null"
              >Patientenprofil</v-tab
            >
            <v-tab
              href="#employeeInformation"
              v-if="address.employee !== undefined && address.employee !== null"
              >Mitarbeiter</v-tab
            >
            <v-tab
              href="#doctorInformation"
              v-if="address.doctor !== undefined && address.doctor !== null"
              >Arzt</v-tab
            >
            <v-tab
              href="#companyInformation"
              v-if="address.company !== undefined && address.company !== null"
              >Unternehmen</v-tab
            >
            <v-tab
              href="#supplierInformation"
              v-if="address.supplier !== undefined && address.supplier !== null"
              >Lieferant</v-tab
            >
            <v-tab
              href="#healthInsuranceCompanyInformation"
              v-if="
                address.healthInsuranceCompany !== undefined &&
                  address.healthInsuranceCompany !== null
              "
              >Krankenkasse</v-tab
            >
            <v-tab href="#contacts">Ansprechpartner</v-tab>
            <v-tab href="#customerGroups">Kundengruppen-Zuordnung</v-tab>
            <v-tab href="#interactions">Interaktionen</v-tab>
            <v-tab href="#documents">Dokumente</v-tab>
            <v-tab
              href="#ekRevenueStatistics"
              v-if="address.supplier !== undefined && address.supplier !== null"
              >EK - Umsatzstatistik</v-tab
            >
            <v-tab
              href="#revenue"
              v-if="
                address.consumer == true ||
                  (address.company !== undefined && address.company !== null) ||
                  (address.patient !== undefined && address.patient !== null)
              "
              >VK - Umsatzstatistik</v-tab
            >

            <v-tab
              href="#KKrevenueStatistics"
              v-if="adressTypeDetailedName.includes('Krankenk.')"
              >KV-Statistik</v-tab
            >

            <v-tab
              href="#prescriptionStatistics"
              v-if="adressTypeDetailedName.includes('Arzt')"
              >Verordnungsstatistik</v-tab
            >
          </v-tabs>
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item value="patientInformation">
            <PatientinformationTab :lfdnr="lfdnr"></PatientinformationTab>
          </v-tab-item>
          <v-tab-item value="employeeInformation">
            <EmployeeInformationTab :lfdnr="lfdnr"></EmployeeInformationTab>
          </v-tab-item>
          <v-tab-item value="doctorInformation">
            <DoctorInformationTab :lfdnr="lfdnr"></DoctorInformationTab>
          </v-tab-item>
          <v-tab-item value="companyInformation">
            <CompanyInformationTab :lfdnr="lfdnr" />
          </v-tab-item>

          <v-tab-item value="supplierInformation">
            <SupplierInformationTab :lfdnr="lfdnr"></SupplierInformationTab>
          </v-tab-item>
          <v-tab-item value="healthInsuranceCompanyInformation">
            <HealthInsuranceCompanyInformationTab
              :lfdnr="lfdnr"
            ></HealthInsuranceCompanyInformationTab>
          </v-tab-item>
          <v-tab-item value="contacts">
            <ContactPersons :lfdnr="lfdnr" />
          </v-tab-item>
          <v-tab-item value="customerGroups">
            <CustomerGroups :lfdnr="lfdnr" />
          </v-tab-item>
          <v-tab-item value="interactions">
            <InteractionsTable :lfdnr="lfdnr" />
          </v-tab-item>
          <v-tab-item value="documents">
            <DocumentsTable :lfdnr="lfdnr" />
          </v-tab-item>
          <v-tab-item value="revenue">
            <Revenue :lfdnr="lfdnr" />
          </v-tab-item>
          <!-- Currently unused -->
          <!-- <v-tab-item value="articleStatistics">
          <p>Artikelbewegungsstatistik inkl. Lagerstand</p>
        </v-tab-item> -->
          <v-tab-item value="prescriptionStatistics">
            <Prescription :lfdnr="lfdnr" />
          </v-tab-item>
          <v-tab-item value="KKrevenueStatistics">
            <KKStatistics :lfdnr="lfdnr" />
          </v-tab-item>
          <v-tab-item value="ekRevenueStatistics">
            <v-container>
              <ArticleTypes :lfdnr="this.lfdnr" />
              <router-view></router-view>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-container max-width="100%" v-else-if="addressExists == 'loading'">
      <v-card :loading="true" class="mx-auto my-12">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title
          class="ml-4 mt-4 mb-n6 d-flex justify-space-between"
          lang="de"
          style="font-size: 3.5vh"
        >
          <h4
            class="mr-8 font-weight-bold text-upercase text-break"
            style="word-wrap: break-word; hyphens: auto"
            v-if="isMobile()"
          >
            <v-skeleton-loader type="text" width="10vw" />

            <v-btn
              color="primary"
              fab
              dark
              class="ml-2"
              @click="showAddressPicturesDialog = true"
              ><v-icon>mdi-image-multiple</v-icon></v-btn
            >
          </h4>
          <h4 class="mr-8 font-weight-bold text-upercase text-break" v-else>
            <v-skeleton-loader type="heading" width="50vw" />
          </h4>

          <div
            :class="
              isMobile() ? 'col col-sm-12 col-lg-4 col-xl-3' : 'd-inline-flex'
            "
          ></div>
        </v-card-title>

        <v-card-text class="d-flex justify-space-between flex-wrap">
          <div class="elevation-0 pa-4 mb-4 col-md-6 col-12">
            <h3 class="mb-4 text-h6">Anschrift</h3>
            <div class="body-1 mb-4">
              <v-row class="ml-0">
                <v-col class="pa-0 col-4"> Straße: </v-col>
                <v-col class="pa-0">
                  <v-skeleton-loader type="text" width="60%" />
                </v-col>
              </v-row>
              <v-row class="ml-0">
                <v-col class="pa-0 col-4"> Ort: </v-col>
                <v-col class="pa-0">
                  <v-skeleton-loader type="text" width="40%" />
                </v-col>
              </v-row>
              <v-row class="ml-0">
                <v-col class="pa-0 col-4"> Land: </v-col>
                <v-col class="pa-0">
                  <v-skeleton-loader type="text" width="70%" />
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="elevation-0 pa-4 mb-4 col-md-6 col-12">
            <h3 class="mb-4 text-h6">Kontaktdaten</h3>
            <div class="body-1 mb-4">
              <v-row class="ml-0">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-phone</v-icon
                  >
                  Telefon:
                </v-col>
                <v-col class="pa-0">
                  <v-skeleton-loader type="text" width="60%" />
                </v-col>
              </v-row>
              <v-row class="ml-0 mt-4">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-phone-outline</v-icon
                  >
                  Telefon 2:
                </v-col>
                <v-col class="pa-0">
                  <v-skeleton-loader type="text" width="40%" />
                </v-col>
              </v-row>
              <v-row class="ml-0 mt-4">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-cellphone</v-icon
                  >
                  Mobil:
                </v-col>
                <v-col class="pa-0">
                  <v-skeleton-loader type="text" width="50%" />
                </v-col>
              </v-row>
              <v-row class="ml-0 mt-6">
                <v-col class="pa-0 col-4">
                  <v-icon class="mr-3" v-if="!isMobile()" small
                    >mdi-email</v-icon
                  >
                  E-Mail:
                  {{ address.mobile }}
                </v-col>
                <v-col class="pa-0">
                  <v-skeleton-loader type="text" width="90%" />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>
      </v-card>
    </v-container>
    <v-container max-width="100%" v-else-if="addressExists == 'false'">
      <v-card class="pa-5" style="border-radius: 15px;">
        <v-layout column align-center justify-center fill-height>
          <v-flex xs12 class="text-center mb-4">
            <h3>
              Die angegebene Adresse konnte nicht gefunden werden.
            </h3>
          </v-flex>
          <v-flex xs12>
            <v-img
              class="mx-auto my-auto"
              src="@/assets/svg/undraw_warning_re_eoyh.svg"
              max-width="350px"
              contain
            ></v-img>
          </v-flex>
          <v-flex xs12 class="text-center mt-4">
            <v-btn color="primary" @click="gotoSearchPage()">
              <v-icon left>mdi-magnify</v-icon>
              Zurück zur Suche
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
      <ErrorDialog
        :failMessage="failMessage"
        :opened="errorDialog"
        @closeDialog="errorDialog = false"
      />
    </v-container>
  </div>
</template>

<script>
import geocoding from '@/services/geocoding/openstreetmap.js'
import InteractionsTable from '@/components/crm/addresses/InteractionsTable.vue'
import ContactPersons from '@/components/crm/addresses/ContactPersons.vue'
import CustomerGroups from '@/components/crm/addresses/CustomerGroups.vue'
import ContactDataDialog from '@/components/crm/addresses/ContactDataDialog.vue'
import AddressPicturesDialog from '@/components/crm/addresses/AddressPicturesDialog.vue'
import DocumentsTable from '@/components/crm/addresses/DocumentsTable.vue'
import AddressTypeChip from '@/components/crm/addresses/AddressTypeChip.vue'
import addressService from '@/services/crm/addressService.js'
import Revenue from '@/components/statistics/Revenue.vue'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import ArticleTypes from '@/components/statistics/EkRevenueStatistics/ArticleTypes.vue'
import Prescription from '@/components/statistics/PrescriptionStatistics/Prescription.vue'
import KKStatistics from '@/components/statistics/KK_Statistics/KK_Statistics.vue'
import L from 'leaflet'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import { mapState } from 'vuex'
import PatientinformationTab from '@/components/crm/addresses/editAddress/PatientInformationTab.vue'
import EmployeeInformationTab from '@/components/crm/addresses/editAddress/EmployeeInformationTab.vue'
import DoctorInformationTab from '@/components/crm/addresses/editAddress/DoctorInformationTab.vue'
import CompanyInformationTab from '@/components/crm/addresses/editAddress/CompanyInformationTab.vue'
import SupplierInformationTab from '@/components/crm/addresses/editAddress/SupplierInformationTab.vue'
import HealthInsuranceCompanyInformationTab from '@/components/crm/addresses/editAddress/HealthInsuranceCompanyInformationTab.vue'
delete L.Icon.Default.prototype._getIconUrl

export default {
  components: {
    PatientinformationTab,
    EmployeeInformationTab,
    DoctorInformationTab,
    CompanyInformationTab,
    SupplierInformationTab,
    HealthInsuranceCompanyInformationTab,
    ContactPersons,
    AddressPicturesDialog,
    CustomerGroups,
    InteractionsTable,
    DocumentsTable,
    AddressTypeChip,
    Revenue,
    ArticleTypes,
    Prescription,
    KKStatistics,
    ContactDataDialog,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    ErrorDialog
  },
  data: () => ({
    addressExists: 'loading',
    tab: '',
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: [],
      loading: true,
      address: {}
    },
    dialogOpen: false,
    dialogTab: null,

    editContactData: false,

    addressTypeName: [],
    adressTypeDetailedName: [],

    showMap: true,
    mapsAddressObject: {
      lat: 0,
      lng: 0
    },
    loading: true,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

    mapOptions: {
      zoomSnap: 0.5
    },

    showAddressPicturesDialog: false,

    failMessage: '',
    errorDialog: false
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      this.tab = this.$route.query.tab
      this.loadAddress()
      this.loadDefaults()
      this.dialogTab = 0

      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      })
    },
    cancel() {
      this.dialogOpen = false
      this.resetValuesToDefault()
    },
    displayName() {
      if (this.isValidPerson) {
        return this.address.firstname + ' ' + this.address.lastname
      } else if (this.isValidNonPerson) {
        return this.address.name
      }
    },
    save() {
      if (this.dialogTab == 0) {
        //--> person
        this.savePerson()
      } else if (this.dialogTab == 1) {
        //--> non-person
        this.saveNonPerson()
      }
      this.resetValuesToDefault()
    },
    savePerson() {
      if (this.person.isPatient) {
        //map healthInsurance company to its key
        this.person.patient.healthInsuranceCompany = {
          lfdnr: this.person.patient.healthInsuranceCompany.key.lfdnr,
          firma: this.person.patient.healthInsuranceCompany.key.firma
        }
        if (this.person.isRelative) {
          this.person.patient.mainInsuranceData.healthInsuranceCompany = {
            lfdnr: this.person.patient.mainInsuranceData.healthInsuranceCompany
              .key.lfdnr,
            firma: this.person.patient.mainInsuranceData.healthInsuranceCompany
              .key.firma
          }
        }
        this.person.patient.svnr = parseInt(this.person.patient.svnr)
      }

      this.$store.dispatch('saveNewPerson', this.person).then(() => {
        this.resetValuesToDefault()
        this.dialogOpen = false
      })
    },
    saveNonPerson() {
      this.$store.dispatch('saveNewNonPerson', this.nonPerson).then(() => {
        this.resetValuesToDefault()
        this.dialogOpen = false
      })
    },
    resetValuesToDefault() {
      this.$store.commit('resetNewPerson')
      this.$store.commit('resetNewNonPerson')
    },
    setValuesToTestValues() {
      this.$store.commit('setValuesToTestValues')
    },

    isValidPerson() {
      return (
        this.validAddress &&
        ((this.person.isPatient && this.validPatient) ||
          !this.person.isPatient) &&
        ((this.person.isRelative && this.validRelative) ||
          !this.person.isRelative) &&
        ((this.person.isEmployee && this.validEmployee) ||
          !this.person.isEmployee) &&
        ((this.person.isDoctor && this.validDoctor) || !this.person.isDoctor) &&
        (this.person.isConsumer ||
          this.person.isAdultRepresentation ||
          this.person.isPatient ||
          this.person.isEmployee ||
          this.person.isDoctor)
      )
    },

    isValidNonPerson() {
      return (
        (this.nonPerson.isCompany ||
          this.nonPerson.isAdultRepresentation ||
          this.nonPerson.isResidence) &&
        this.validGeneralInfo &&
        ((this.nonPerson.isCompany && this.validCompany) ||
          !this.nonPerson.isCompany)
      )
    },
    isValid() {
      if (this.dialogTab == 0) {
        return this.isValidPerson()
      } else if (this.dialogTab == 1) {
        return this.isValidNonPerson()
      }
    },
    loadDefaults() {
      this.$store.dispatch('loadAddAddressOptions')
    },
    gotoSearchPage() {
      this.$router.push({ name: 'CRM' })
    },
    updateContactData(updatedAddress) {
      this.address = { ...updatedAddress }
    },

    closeAddressPicturesDialog() {
      this.showAddressPicturesDialog = false
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    //load Address from Backend
    loadAddress() {
      addressService
        .getAddress(this.lfdnr)
        .then(response => {
          console.warn(response)
          if (response.status != 200) {
            this.addressExists = 'false'
          } else {
            this.addressExists = 'true'
          }
          this.address = response.data
          this.loading = false
          this.addressTypeName = this.address.addressTypes

          /*this.addressTypeName.forEach(addressTypeNameElement => {
            this.adressTypeDetailedName.push(
              addressService.getAddressTypeDetailed(addressTypeNameElement).name
            )
          })*/

          // Use openstreetmap to get geocode from address
          this.showMap = true

          geocoding.getGeocodeByAddress(this.address.address).then(response => {
            if (response.data.length == 0) {
              this.showMap = false
              return
            }

            this.mapsAddressObject.lat = parseFloat(response.data[0].lat)
            this.mapsAddressObject.lng = parseFloat(response.data[0].lon)
          })
        })
        .catch(error => {
          console.log(error)
        })
    },

    // open Google Maps and show the address
    openAddressInGoogleMaps() {
      let addressString = `www.google.com/maps/search/?&q=${this.address.address.street},${this.address.address.zip} ${this.address.address.city}`

      if (
        /* if we'
        re on iOS, open in Apple Maps */
        navigator.platform.indexOf('iPhone') != -1 ||
        navigator.platform.indexOf('iPad') != -1 ||
        navigator.platform.indexOf('iPod') != -1
      )
        window.open(`maps://${addressString}`)
      /* else use Google */ else window.open(`https://${addressString}`)
    },
    // open Google Maps and start Navigation right away
    openAddressInGoogleMapsWithNavigation() {
      let addressString = `www.google.com/maps/dir/?api=1&destination=${this.address.address.street},${this.address.address.zip} ${this.address.address.city}&dir_action=navigate`

      if (
        /* if we're on iOS, open in Apple Maps */
        navigator.platform.indexOf('iPhone') != -1 ||
        navigator.platform.indexOf('iPad') != -1 ||
        navigator.platform.indexOf('iPod') != -1
      )
        window.open(`maps://${addressString}`)
      /* else use Google */ else window.open(`https://${addressString}`)
    },
    addressTypesIcons(item) {
      return addressService.getAddressTypeDetailed(item).icon
    },
    addressTypesNames(item) {
      //this.adressTypeName.push(addressService.getAddressTypeDetailed(item).name)
      return addressService.getAddressTypeDetailed(item).name
    }
  },
  computed: {
    address() {
      return this.address
    },
    allRoles() {
      const roles = []

      if (this.address.patient !== null && this.address.patient !== undefined) {
        roles.push({ name: 'Patient', icon: 'fas fa-user-injured' })
      }
      if (this.address.consumer == true) {
        roles.push({ name: 'Konsument', icon: 'fas fa-user' })
      }
      if (
        this.address.healthInsuranceCompany !== undefined &&
        this.address.healthInsuranceCompany !== null
      ) {
        roles.push({ name: 'Krankenkasse', icon: 'fas fa-hospital-user' })
      }
      if (this.address.doctor !== undefined && this.address.doctor !== null) {
        roles.push({ name: 'Arzt', icon: 'fas fa-user-md' })
      }
      if (this.address.isAdultRepresentation == true) {
        roles.push({ name: 'Erwachsenenverteter', icon: 'fas fa-handshake' })
      }
      if (
        this.address.supplier !== undefined &&
        this.address.supplier !== null
      ) {
        roles.push({ name: 'Lieferant', icon: 'mdi-dolly' })
      }
      if (this.address.residence == true) {
        roles.push({ name: 'Heim', icon: 'fas fa-home' })
      }
      if (this.address.company !== null && this.address.company !== undefined) {
        roles.push({ name: 'Firma', icon: 'fas fa-building' })
      }
      if (
        this.address.employee !== null &&
        this.address.employee !== undefined
      ) {
        roles.push({ name: 'Mitarbeiter', icon: 'mdi-cash-register' })
      }
      //todo: chip für interessen
      // if(this.address.interessent !== null && this.address.interessent !== undefined){
      // roles.push({name: 'interessent', icon: 'name-vom-icon'})
      // oder wenn es true / false geschickt wird:
      // if(this.address.interessent == true)
      // {
      // roles.push({name: 'Interessent', icon: 'name-vom-icon'})
      //}
      //}
      return roles
    },
    telLink() {
      return 'tel:' + this.address.tel
    },
    mailLink() {
      return 'mailto:' + this.address.email
    },
    ...mapState({
      person: state => state.crmAddresses.newPerson,
      nonPerson: state => state.crmAddresses.newNonPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      validPatient: state => state.crmAddresses.validPatient,
      validRelative: state => state.crmAddresses.validRelative,
      validEmployee: state => state.crmAddresses.validEmployee,
      validDoctor: state => state.crmAddresses.validDoctor,
      validGeneralInfo: state => state.crmAddresses.validGeneralInfo,
      validCompany: state => state.crmAddresses.validCompany
    })
  },
  watch: {
    '$route.params': 'init',

    tab() {
      const tab = this.tab
      if (this.$route.query.tab != tab)
        this.$router.replace({ query: { ...this.$route.query, tab } })
    }
  },

  mounted() {
    this.init()
  }
}
</script>

<style>
.addressPictureBtns {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  justify-content: center;
}
.tabWidthFill {
  display: flex;
}
</style>
