<template>
  <v-container>
    <div class="grey--text text--darken-2 mb-5">
      <h3>
        {{ resultTypeName }}

        -> Artikelkategorien
      </h3>
    </div>

    <div class="mx-auto mb-12">
      <v-switch
        class="ml-4"
        v-model="showAmountLocal"
        label="Werte-/Mengendarstellung"
      />

      <v-data-table
        :headers="headers"
        :items="showAmount === true ? resultAmounts : resultValues"
        item-key="code"
        :items-per-page="5"
        :search="search"
        class="datatable-row-pointer"
        :loading="loading"
        sort-by="description"
        :sort-desc="sortDesc"
        mobile-breakpoint="800"
        @click:row="openArticleCategory"
        :item-class="itemRowBackground3"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            outlined
            hide-details
            dense
          ></v-text-field>
        </template>
        <template v-slot:no-data>
          Keine Artikelkategorien in diesem Zeitraum vorhanden.
        </template>
        <template
          v-for="header in headers.filter(
            header => header.value !== 'description'
          )"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          <span v-bind:key="header.text">{{
            formatArticleTypesTableValue(value)
          }}</span>
        </template>
      </v-data-table>
    </div>

    <div v-show="articlesShow">
      <Articles
        v-bind:lfdnr="this.lfdnr"
        v-bind:articleType="this.articleType"
        v-bind:category="this.category"
      />
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

// Services
import addressService from '@/services/crm/addressService.js'
import distributorService from '@/services/statistics/distributorService.js'
import currencyFormatService from '@/services/formatters/currencyFormatService.js'
import Articles from '@/components/statistics/EkRevenueStatistics/Articles.vue'

// Components

export default {
  components: {
    Articles
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    resultTypeName: {},
    resultValues: [],
    resultAmounts: [],
    articleNrTemp: '',
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    category: 0,
    headers: [
      {
        text: 'Artikelkategorie',
        align: 'start',
        value: 'description'
      },
      {
        text: 'Anfangsbestand',
        value: 'opening',
        align: 'end'
      },
      {
        text: 'Einkäufe',
        value: 'purchases',
        align: 'end'
      },
      {
        text: 'Verkäufe',
        value: 'sales',
        align: 'end'
      },
      {
        text: 'Inventur',
        value: 'inventory',
        align: 'end'
      },
      {
        text: 'Materialverbrauch',
        value: 'materialsInput',
        align: 'end'
      },
      {
        text: 'Endbestand',
        value: 'finalAmount',
        align: 'end'
      }
    ]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data
      })
      this.loadArticleCategoriesStatistics()
    },
    loadArticleCategoriesStatistics() {
      this.loading = true
      distributorService
        .getArticleCategoriesStatistics(
          this.lfdnr,
          this.articleType,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          // Filter out zero-lines
          const categoriesValuesFiltered = []
          const categoriesAmountsFiltered = []
          response.data.categories.forEach(category => {
            if (
              false ==
              (category.openingValue === 0 &&
                category.purchasesValue === 0 &&
                category.salesValue === 0 &&
                category.inventoryValue === 0 &&
                category.materialsInputValue === 0 &&
                category.finalValue === 0)
            )
              categoriesValuesFiltered.push({
                nr: category.nr,
                description: category.description,
                opening: category.openingValue,
                purchases: category.purchasesValue,
                sales: category.salesValue,
                inventory: category.inventoryValue,
                materialsInput: category.materialsInputValue,
                finalAmount: category.finalValue
              })
            if (
              false ==
              (category.openingAmount === 0 &&
                category.purchasesAmount === 0 &&
                category.salesAmount === 0 &&
                category.inventoryAmount === 0 &&
                category.materialsInputAmount === 0 &&
                category.finalAmount === 0)
            )
              categoriesAmountsFiltered.push({
                nr: category.nr,
                description: category.description,
                opening: category.openingAmount,
                purchases: category.purchasesAmount,
                sales: category.salesAmount,
                inventory: category.inventoryAmount,
                materialsInput: category.materialsInputAmount,
                finalAmount: category.finalAmount
              })
          })

          this.resultTypeName = response.data.typeName
          this.resultValues = categoriesValuesFiltered
          this.resultAmounts = categoriesAmountsFiltered

          this.loading = false
        })
    },

    itemRowBackground3: function(item) {
      if (item.nr == this.articleNrTemp) {
        return 'style-1'
      } else {
        return 'style-2'
      }
    },

    openArticleCategory(item) {
      console.log(item.nr)

      if (this.articleNrTemp == item.nr) {
        this.articleNrTemp = ''
        // TODO: FIX MUTATING PROPS
        // eslint-disable-next-line
        this.articlesShow = false
      } else {
        this.articleNrTemp = item.nr
        this.category = item.nr
        // eslint-disable-next-line
        this.articlesShow = true
      }

      // statisticsDistributorArticleCategories
      /*this.$router.push({
        name: 'statisticsDistributorArticles',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: {
          lfdnr: this.lfdnr,
          articleType: this.articleType,
          category: item.nr
        }
      })*/
    },
    formatArticleTypesTableValue(value) {
      if (this.showAmount === true) return value == 0 ? '' : value + ''
      else return value == 0 ? '' : currencyFormatService.formatEuro(value)
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showAmount: state => state.distributors.showAmount
    }),
    showAmountLocal: {
      get() {
        return this.showAmount
      },
      set(value) {
        this.$store.dispatch('setShowAmount', value)
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    filterFromDate: function() {
      this.loadArticleCategoriesStatistics()
    },
    filterToDate: function() {
      this.loadArticleCategoriesStatistics()
    },
    articleType: function() {
      this.init()
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

* >>> .style-1 {
  background-color: #8fc5e1;
}
* >>> .style-1:hover {
  background-color: #8fc5e1 !important;
}
* >>> .style-2 {
  background-color: white;
}
</style>
