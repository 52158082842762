<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche (Dokument, Artikel)"
        outlined
        hide-details
        clearable
        style="margin-bottom: 35px"
      ></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-row no-gutters class="mt-n7 mb-4 d-flex align center">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: bold"
              >Filter</v-expansion-panel-header
            >
            <v-expansion-panel-content style="margin-top: 30px">
              <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-menu
                    v-model="datePickerMenu1"
                    :close-on-content-click="false"
                    max-width="400px"
                    min-width="auto"
                    class="mx-sm-1"
                    offset-y
                    transition="scale-transition"
                    origin="center center"
                    ref="datePickerMenu1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate1"
                        clearable
                        label="Startdatum"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date1 = null"
                        required
                        outlined
                        class="mx-sm-1"
                        append-icon="mdi-calendar"
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" color="primary">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="secondary"
                        @click="datePickerMenu1 = false"
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePickerMenu1.save(date1)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-menu
                    v-model="datePickerMenu2"
                    :close-on-content-click="false"
                    max-width="290"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                    origin="center center"
                    ref="datePickerMenu2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate2"
                        clearable
                        label="Enddatum"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date2 = null"
                        required
                        outlined
                        class="mx-sm-1"
                        append-icon="mdi-calendar"
                        solo
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="date2" color="primary">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="secondary"
                        @click="datePickerMenu2 = false"
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePickerMenu2.save(date2)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    label="Dokumenttyp"
                    required
                    v-model="selectedDocumentType"
                    :items="documents"
                    item-text="type"
                    outlined
                    class="mx-sm-1"
                    transition="scale-transition"
                    origin="center center"
                    clearable
                    small-chips
                    solo
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    label="Status"
                    required
                    v-model="selectedStatus"
                    outlined
                    :items="documents"
                    item-text="state"
                    class="mx-sm-1"
                    transition="scale-transition"
                    origin="center center"
                    clearable
                    small-chips
                    solo
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="" lg="" xl="">
                  <v-tooltip top color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        @click="resetFilter()"
                        class="white--text mx-sm-1 "
                        style="float: right;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <span>Filter löschen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="documentsWithPreparedPositions"
      :items-per-page="10"
      :search="search"
      item-key="name"
      class="elevation-1"
      sort-by="date"
      :single-expand="true"
      :sort-desc="sortDesc"
      show-expand
      @update:expanded="openRow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-menu v-if="isUserBusinessAuthorized" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" depressed v-bind="attrs" v-on="on">
                Dokument erstellen
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="documentType in availableDocumentTypes"
                :key="documentType.id"
                :to="{
                  name: `Business`,
                  query: { address: lfdnr, documentTypeId: documentType.id }
                }"
              >
                <v-list-item-title>{{ documentType.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: 'crmAddressDocumentDetailed',
            params: { lfdnr: lfdnr, wf_cid: item.wf_cid }
          }"
        >
          <div
            v-for="item in items"
            :key="item.id"
            @click="printDocument(item)"
          ></div>
          {{ item.name }}
        </router-link>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="py-5">
          <div class="d-flex justify-space-between flex-wrap">
            <div class="my-4 col-12 col-md-5 col-lg-4 col-xl-3">
              <h3 class="text-center mb-3">
                Zeitverlauf
              </h3>

              <v-treeview
                item-key="wf_cid"
                hoverable
                activatable
                color="primary"
                :active.sync="selectedTreeViewArray"
                :items="treeViewItems"
                class=" font-weight-medium no-select"
                open
              >
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="item">
                    mdi-file-document-outline
                  </v-icon>
                </template>
                <template v-slot:append="{}"> </template>
              </v-treeview>
            </div>
            <v-divider
              v-show="$vuetify.breakpoint.mdAndUp"
              vertical
              class="mt-6 mb-2"
            ></v-divider>

            <!-- DETAILANSICHT -->
            <div class="col-12 col-md-7 col-xl-8 mt-4">
              <h3 v-if="!selectedTreeViewItem" class="text-center mb-3">
                Enthaltene Artikel in
                <router-link
                  :to="{
                    name: 'crmAddressDocumentDetailed',
                    params: {
                      lfdnr: lfdnr,
                      wf_cid: treeViewItems[0].wf_cid
                    }
                  }"
                >
                  {{ treeViewItems[0].name }}
                </router-link>
              </h3>
              <h3 v-else class="text-center mb-3">
                Enthaltene Artikel in
                <router-link
                  :to="{
                    name: 'crmAddressDocumentDetailed',
                    params: {
                      lfdnr: lfdnr,
                      wf_cid: selectedTreeViewItem.wf_cid
                    }
                  }"
                >
                  {{ selectedTreeViewItem.name }}
                </router-link>
              </h3>

              <v-data-table
                :items="item.positions"
                :headers="positionHeaders"
                :items-per-page="5"
                item-key="articleNr"
                class="mt-3"
                sort-by="articleNr"
              >
                <template v-slot:item.articleDescription="{ item }">
                  {{
                    item.articleDescription == null
                      ? '-'
                      : item.articleDescription
                  }}
                </template>

                <template v-slot:item.articleDescription2="{ item }">
                  {{
                    item.articleDescription2 == null
                      ? '-'
                      : item.articleDescription2
                  }}
                </template>

                <template v-slot:no-data>
                  Keine Artikel vorhanden
                </template>
              </v-data-table>
            </div>
          </div>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <OpenLinkEyeComponent
          :destination="{
            name: 'crmAddressDocumentDetailed',
            params: { lfdnr: lfdnr, wf_cid: item.wf_cid }
          }"
          :newTab="false"
        />

        <v-btn
          icon
          outlined
          @click="printDocument(item)"
          :loading="item.printLoading"
          class="mr-2 col-xl-5"
        >
          <v-icon small> mdi-printer </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data> Keine Dokumente vorhanden </template>
    </v-data-table>

    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import documentService from '@/services/crm/documentService.js'
import OpenLinkEyeComponent from '@/components/core/OpenLinkEyeComponent.vue'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import moment from 'moment'

export default {
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  components: {
    OpenLinkEyeComponent,
    ErrorDialog
  },
  data() {
    return {
      search: '',
      expanded: [],
      sortDesc: true,
      items: [],
      headers: [
        {
          text: 'Datum',
          align: 'start',
          value: 'date'
        },
        { text: 'Name', value: 'name' },
        { text: 'Typ', value: 'type' },

        { text: 'Status', value: 'state', sortable: false },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '12em'
        },
        {
          value: 'positionsString',
          align: ' d-none' // for invisibility, keeps search ability, space is important!
        }
      ],
      positionHeaders: [
        {
          text: 'Artikel-Nr.',
          value: 'articleNr'
        },
        {
          text: 'Artikel-Bezeichnung',
          value: 'articleDescription'
        },
        {
          text: 'Artikel-Bezeichnung 2',
          value: 'articleDescription2'
        }
      ],
      selectedTreeViewArray: [],
      emptyTreeViewItems: [],
      treeViewItems: [
        {
          wf_cid: 1,
          name: 'Applications :',
          children: [
            { wf_cid: 2, name: 'Calendar : app' },
            { wf_cid: 3, name: 'Chrome : app' },
            { wf_cid: 4, name: 'Webstorm : app' }
          ]
        },
        {
          wf_cid: 5,
          name: 'Documents :',
          children: [
            {
              wf_cid: 6,
              name: 'vuetify :',
              children: [
                {
                  wf_cid: 7,
                  name: 'src :',
                  children: [
                    { wf_cid: 8, name: 'index : ts' },
                    { wf_cid: 9, name: 'bootstrap : ts' }
                  ]
                }
              ]
            },
            {
              wf_cid: 10,
              name: 'material2 :',
              children: [
                {
                  wf_cid: 11,
                  name: 'src :',
                  children: [
                    { wf_cid: 12, name: 'v-btn : ts' },
                    { wf_cid: 13, name: 'v-card : ts' },
                    { wf_cid: 14, name: 'v-window : ts' }
                  ]
                }
              ]
            }
          ]
        },
        {
          wf_cid: 15,
          name: 'Downloads :',
          children: [
            { wf_cid: 16, name: 'October : pdf' },
            { wf_cid: 17, name: 'November : pdf' },
            { wf_cid: 18, name: 'Tutorial : html' }
          ]
        },
        {
          wf_cid: 19,
          name: 'Videos :',
          children: [
            {
              wf_cid: 20,
              name: 'Tutorials :',
              children: [
                { wf_cid: 21, name: 'Basic layouts : mp4' },
                { wf_cid: 22, name: 'Advanced techniques : mp4' },
                { wf_cid: 23, name: 'All about app : dir' }
              ]
            },
            { wf_cid: 24, name: 'Intro : mov' },
            { wf_cid: 25, name: 'Conference introduction : avi' }
          ]
        }
      ],
      failMessage: '',
      errorDialog: false,
      date: null,
      date1: '',
      date2: '',
      datePickerMenu1: false,
      datePickerMenu2: false,
      selectedDocumentType: null,
      selectedStatus: null,
      allDocumentType: null,
      allStatus: null,
      documentTypSelectorItems: ['item1', 'item2', 'item3', 'item4'],
      statusSelectorItems: ['10 offen', 'item2', 'item3'],
      availableDocumentTypes: [
        {
          id: 30,
          name: 'Angebot'
        },
        {
          id: 35,
          name: 'Auftrag'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      documents: state => state.crmDocuments.documents
    }),
    isUserBusinessAuthorized() {
      console.log(this.$store.getters.userHasApp('Business'))
      return this.$store.getters.userHasApp('Business')
    },
    documentsFiltered() {
      this.fillFilter()
      return this.documents.filter(item => item.show)
    },
    documentsWithPreparedPositions() {
      let temp = this.documents.filter(item => {
        let startDateTrue =
          this.date1 == null || this.date1 === '' || item.date > this.date1
        let endDateTrue =
          this.date2 == null || this.date2 === '' || item.date < this.date2
        let documentTypeTrue =
          this.selectedDocumentType == null ||
          item.type === this.selectedDocumentType
        let statusTrue =
          this.selectedStatus == null || item.state === this.selectedStatus

        return startDateTrue && endDateTrue && documentTypeTrue && statusTrue
      })

      let beginDateFormat = new Date(this.date1)
      let endDateFormat = new Date(this.date2)

      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum')
      }

      return (
        this.documents.map(d => {
          let posString = ''
          for (const pos of d.positions)
            posString += `${pos.articleNr} ${pos.articleDescription} ${pos.articleDescription2} `

          return temp && { ...d, positionsString: posString }
        }) && temp
      )
    },
    selectedTreeViewItem() {
      if (this.selectedTreeViewArray.length == 0) return undefined

      const id = this.selectedTreeViewArray[0]
      var item = this.documents.find(doc => doc.wf_cid === id)

      return this.convertItemToTreeViewItem(item)
    },
    formattedDate1() {
      moment.locale()
      return this.date1 ? moment(this.date1).format('ll') : ''
    },
    formattedDate2() {
      moment.locale()
      return this.date2 ? moment(this.date2).format('ll') : ''
    },
    formattedTreeItems() {
      return this.treeViewItems
    }
  },
  methods: {
    init() {
      this.loadDocuments()
      this.fillFilter()
    },
    convertItemToTreeViewItem(item) {
      return {
        wf_cid: item.wf_cid,
        name: item.name,
        children: item.childrenDocuments
      }
    },
    loadDocuments() {
      this.$store
        .dispatch('loadDocuments', this.lfdnr)
        .then((this.loading = false))
    },
    printDocument(item) {
      documentService.printDocument(item).catch(error => {
        this.failMessage = 'Das Drucken ist fehlgeschlagen!'
        this.errorDialog = true
        console.error(error)
        item.printLoading = false
      })
    },
    getTreeViewItems(item) {
      let leaf = {
        wf_cid: item.wf_cid,
        name: item.shortType + ' ' + item.nr
      }

      if (item.childrenDocuments.length != 0) {
        //parent document exists
        leaf.children = [this.getTreeViewItems(item.childrenDocuments[0])]
        return leaf
      } else {
        //parent document does not exists
        return leaf
      }
    },
    openRow(item) {
      if (item.length != 0) {
        this.treeViewItems = [this.getTreeViewItems(item[0])]
        this.selectedTreeViewArray = []
      }
    },
    resetFilter() {
      this.date1 = null
      this.date2 = null
      this.selectedDocumentType = null
      this.selectedStatus = null
    },
    fillFilter() {
      if (this.date1 == null || '') {
        this.date1 = ''
      }
      if (this.date2 == null || '') {
        this.date2 = ''
      }

      this.documents.forEach(elem => {
        this.allDocumentType.push(elem.selectedDocumentType)
        this.allStatus.push(elem.selectedStatus)
      })
    },
    displayTreeviewData() {
      return true
    }
  },
  watch: {
    lfdnr() {
      this.init()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style>
.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgba(0, 0, 0, 0);
}
</style>
