<template>
  <v-row>
    <v-col>
      <v-menu
        v-model="filterFromDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Von"
            prepend-icon="mdi-calendar"
            readonly
            :value="filterFromDate"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filterFromDate"
          no-title
          type="month"
          color="primary"
          @input="filterFromDateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        v-model="filterToDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Bis"
            prepend-icon="mdi-calendar"
            readonly
            :value="filterToDate"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filterToDate"
          no-title
          type="month"
          color="primary"
          @input="filterToDateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  data: () => ({
    filterFromDateMenu: false,
    filterToDateMenu: false
  }),
  computed: {
    filterFromDate: {
      get() {
        return this.$store.state.statisticsTimeFilter.fromDate
      },
      set(value) {
        this.$store.dispatch('setFilterFromDate', value)
        this.updateUrl()
      }
    },
    filterToDate: {
      get() {
        return this.$store.state.statisticsTimeFilter.toDate
      },
      set(value) {
        this.$store.dispatch('setFilterToDate', value)
        this.updateUrl()
      }
    }
  },
  methods: {
    updateUrl() {
      if (this.filterFromDate != '' && this.filterToDate != '')
        this.$router.replace({
          query: {
            ...this.$route.query,
            from: this.filterFromDate,
            to: this.filterToDate
          }
        })
    }
  },
  mounted() {
    // extract 'from' date from url
    if (this.$route.query['from'] != null) {
      this.filterFromDate = this.$route.query['from']
    } else {
      this.filterFromDate = DateTime.now()
        .minus({ years: 1 })
        .toFormat('yyyy-MM')
    }

    // extract 'to' date from url
    if (this.$route.query['to'] != null) {
      this.filterToDate = this.$route.query['to']
    } else {
      this.filterToDate = DateTime.now().toFormat('yyyy-MM')
    }
  }
}
</script>

<style></style>
