<template>
  <v-container>
    <div class="grey--text text--darken-2 mb-5">
      <h3>
        {{ resultTypeName }}

        ->

        {{ resultCategoryName }}

        -> Artikel
      </h3>
    </div>

    <div class="mx-auto mb-12">
      <v-switch
        class="ml-4"
        v-model="showAmountLocal"
        label="Werte-/Mengendarstellung"
      />

      <v-data-table
        :headers="headers"
        :items="showAmount === true ? resultAmounts : resultValues"
        :items-per-page="5"
        :search="search"
        item-key="code"
        class="datatable-row-pointer"
        :loading="loading"
        sort-by="description"
        :sort-desc="sortDesc"
        mobile-breakpoint="800"
        @click:row="openArticle"
        :item-class="itemRowBackground4"
        ref="datatable"
        @current-items="test"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            outlined
            hide-details
            dense
            class="mb-2"
          ></v-text-field>
          <v-select
            v-if="showAmount"
            v-model="selectedArticleGroup"
            @change="filterArticleGroup"
            outlined
            dense
            label="Artikelgruppe"
            :items="articleGroupsAmounts"
          ></v-select>
          <v-select
            v-if="!showAmount"
            v-model="selectedArticleGroup"
            @change="filterArticleGroup"
            outlined
            dense
            label="Artikelgruppe"
            :items="articleGroupsValues"
          ></v-select>
        </template>
        <template v-slot:no-data>
          Keine Artikel in diesem Zeitraum vorhanden.
        </template>
        <template v-slot:item.images="{ item }">
          <v-img
            v-if="item.images[0] !== undefined"
            height="50px"
            width="50px"
            contain
            v-bind:src="item.images[0].image"
            class="ma-1"
            @click="openImage(item.images)"
          />
        </template>
        <template v-slot:item.opening="{ item }">
          {{ formatEuroOrZero(item, 'opening') }}
        </template>
        <template v-slot:item.purchases="{ item }">
          {{ formatEuroOrZero(item, 'purchases') }}
        </template>
        <template v-slot:item.sales="{ item }">
          {{ formatEuroOrZero(item, 'sales') }}
        </template>
        <template v-slot:item.inventory="{ item }">
          {{ formatEuroOrZero(item, 'inventory') }}
        </template>
        <template v-slot:item.materialsInput="{ item }">
          {{ formatEuroOrZero(item, 'materialsInput') }}
        </template>
        <template v-slot:item.finalAmount="{ item }">
          {{ formatEuroOrZero(item, 'finalAmount') }}
        </template>
      </v-data-table>
      <v-data-table hide-default-footer :headers="headers2" :items="sum" dense>
        <template v-slot:item.nr="{ item }">
          <b>{{ item.nr }}</b>
        </template>
        <template v-slot:item.image_base64="{ item }">
          <v-img
            height="50px"
            width="50px"
            contain
            v-bind:src="item.image"
            class="ma-1"
            @click="openImage(item.image)"
          />
        </template>
        <template v-slot:no-data>
          Keine Artikel in diesem Zeitraum vorhanden.
        </template>

        <template v-slot:item.opening="{ item }">
          {{ formatEuroOrZeroSum(item.opening) }}
        </template>
        <template v-slot:item.purchases="{ item }">
          {{ formatEuroOrZeroSum(item.purchases) }}
        </template>
        <template v-slot:item.sales="{ item }">
          {{ formatEuroOrZeroSum(item.sales) }}
        </template>
        <template v-slot:item.inventory="{ item }">
          {{ formatEuroOrZeroSum(item.inventory) }}
        </template>
        <template v-slot:item.materialsInput="{ item }">
          {{ formatEuroOrZeroSum(item.materialsInput) }}
        </template>
        <template v-slot:item.finalAmount="{ item }">
          {{ formatEuroOrZeroSum(item.finalAmount) }}
        </template>
      </v-data-table>
    </div>
    <div v-show="articlesDetailedShow">
      <ArticlesDetailed
        v-if="this.artNr != '0'"
        v-bind:lfdnr="this.lfdnr"
        v-bind:artNr="this.artNr"
      />
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

// Services
import addressService from '@/services/crm/addressService.js'
import distributorService from '@/services/statistics/distributorService.js'
import currencyFormatService from '@/services/formatters/currencyFormatService.js'

// Components
import ArticlesDetailed from '@/components/statistics/EkRevenueStatistics/ArticleDetailed.vue'
import axios from 'axios'

export default {
  components: {
    ArticlesDetailed
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },

    resultTypeName: '',
    resultCategoryName: '',
    resultAmounts: [],
    resultValues: [],

    articlesDetailedShow: false,
    articleNrTemp: '',
    loading: true,
    search: '',
    sortDesc: false,
    artNr: '0',
    editorContent: '',
    sum: [
      {
        nr: 'Gesamt',
        description: '',
        opening: '',
        purchases: '',
        sales: '',
        inventory: '',
        materialsInput: '',
        finalAmount: ''
      }
    ],
    headers: [
      {
        text: 'Bild',
        align: 'start',
        sortable: false,
        value: 'images',
        width: '5%'
      },
      {
        text: 'Artikel Nr.',
        align: 'start',
        value: 'nr',
        width: '5%'
      },
      {
        text: 'Artikelgruppe',
        align: 'start',
        value: 'articleGroup',
        width: '10%'
      },
      {
        text: 'Bezeichnung',
        align: 'start',
        value: 'description',
        width: '10%'
      },
      {
        text: 'Anfangsbestand',
        value: 'opening',
        align: 'end',
        width: '10%'
      },
      {
        text: 'Einkäufe',
        value: 'purchases',
        align: 'end',
        width: '10%'
      },
      {
        text: 'Verkäufe',
        value: 'sales',
        align: 'end',
        width: '10%'
      },
      {
        text: 'Inventur',
        value: 'inventory',
        align: 'end',
        width: '10%'
      },
      {
        text: 'Materialverbrauch',
        value: 'materialsInput',
        align: 'end',
        width: '15%'
      },
      {
        text: 'Endbestand',
        value: 'finalAmount',
        align: 'end',
        width: '15%'
      }
    ],
    headers2: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'image_base64',
        width: '5%'
      },
      {
        text: '',
        align: 'start',
        value: 'nr',
        width: '5%'
      },
      {
        text: '',
        align: 'start',
        value: 'articleGroup',
        width: '10%'
      },
      {
        text: '',
        align: 'start',
        value: 'description',
        width: '20%'
      },
      {
        text: '',
        value: 'opening',
        align: 'end',
        width: '10%'
      },
      {
        text: '',
        value: 'purchases',
        align: 'end',
        width: '10%'
      },
      {
        text: '',
        value: 'sales',
        align: 'end',
        width: '10%'
      },
      {
        text: '',
        value: 'inventory',
        align: 'end',
        width: '10%'
      },
      {
        text: '',
        value: 'materialsInput',
        align: 'end',
        width: '15%'
      },
      {
        text: '',
        value: 'finalAmount',
        align: 'end',
        width: '15%'
      }
    ],
    articleGroupsValues: [],
    articleGroupsAmounts: [],
    selectedArticleGroup: ''
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    },
    category: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data
      })
      this.loadArticlesStatistics()
    },
    loadArticlesStatistics() {
      this.loading = true
      distributorService
        .getArticlesOfCategoryStatistics(
          this.lfdnr,
          this.articleType,
          this.category,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          // Filter out zero-lines
          const filteredArticlesValues = []
          const filteredArticlesAmounts = []
          response.data.articles.forEach(article => {
            if (
              false ==
              (article.openingValue === 0 &&
                article.purchasesValue === 0 &&
                article.salesValue === 0 &&
                article.inventoryValue === 0 &&
                article.materialsInputValue === 0 &&
                article.finalValue === 0)
            )
              if (!this.articleGroupsValues.includes(article.articleGroup)) {
                this.articleGroupsValues.push(article.articleGroup)
              }
            filteredArticlesValues.push({
              nr: article.nr,
              articleGroup: article.articleGroup,
              description: article.description,
              opening: article.openingValue,
              purchases: article.purchasesValue,
              sales: article.salesValue,
              inventory: article.inventoryValue,
              materialsInput: article.materialsInputValue,
              finalAmount: article.finalValue,
              images: [],
              unit: article.unit
            })

            if (
              false ==
              (article.openingAmount === 0 &&
                article.purchasesAmount === 0 &&
                article.salesAmount === 0 &&
                article.inventoryAmount === 0 &&
                article.materialsInputAmount === 0 &&
                article.finalAmount === 0)
            )
              if (!this.articleGroupsAmounts.includes(article.articleGroup)) {
                this.articleGroupsAmounts.push(article.articleGroup)
              }
            filteredArticlesAmounts.push({
              nr: article.nr,
              articleGroup: article.articleGroup,
              description: article.description,
              opening: article.openingAmount,
              purchases: article.purchasesAmount,
              sales: article.salesAmount,
              inventory: article.inventoryAmount,
              materialsInput: article.materialsInputAmount,
              finalAmount: article.finalAmount,
              images: [],
              unit: article.unit
            })
          })

          this.resultTypeName = response.data.typeName
          this.resultCategoryName = response.data.categoryName
          this.resultValues = filteredArticlesValues
          this.resultAmounts = filteredArticlesAmounts

          this.loadArticleImages()
        })
    },
    loadArticleImages() {
      this.resultAmounts.forEach(article => {
        this.loading = true
        axios
          .get(`/v1/statistics/distributors/article/${article.nr}/images`)
          .then(data => {
            if (data.data.length > 0) {
              article.images = data.data
              this.resultValues.find(entry => entry.nr === article.nr).images =
                data.data
            }
            this.loading = false
          })
      })
      console.warn(this.resultAmounts)
    },
    openArticle(item) {
      if (this.articleNrTemp == item.nr) {
        this.articleNrTemp = ''
        this.articlesDetailedShow = false
      } else {
        this.articleNrTemp = item.nr

        this.artNr = item.nr
        this.articlesDetailedShow = true
      }

      /*this.$router.push({
        name: 'statisticsArticle',
        params: { lfdnr: this.lfdnr, artNr: item.nr },
        query: this.$route.query
      })*/
    },
    itemRowBackground4: function(item) {
      if (item.nr == this.articleNrTemp) {
        return 'style-1'
      } else {
        return 'style-2'
      }
    },
    formatEuroOrZero(value, key) {
      if (this.showAmount === true)
        return value[key] == 0 ? '' : value[key] + ' ' + value['unit']
      else
        return value[key] == 0
          ? ''
          : currencyFormatService.formatEuro(value[key])
    },
    formatEuroOrZeroSum(value) {
      if (this.showAmount === true) return value == 0 ? '' : value + ' '
      else return value == 0 ? '' : currencyFormatService.formatEuro(value)
    },
    openImage(images) {
      var display = []
      images.forEach(img => {
        display.push(img.image)
      })

      if (display[0] !== null)
        this.$viewerApi({
          images: display
        })
    },
    test(value) {
      console.warn('current items')
      console.warn(value)

      var opening = 0
      var purchases = 0
      var sales = 0
      var inventory = 0
      var materialsInput = 0
      var finalAmount = 0
      value.forEach(entry => {
        opening = opening + entry.opening
        purchases = purchases + entry.purchases
        sales = sales + entry.sales
        inventory = inventory + entry.inventory
        materialsInput = materialsInput + entry.materialsInput
        finalAmount = finalAmount + entry.finalAmount
      })
      this.sum = []
      var toAdd = {
        images: '',
        nr: 'Gesamt',
        description: '',
        opening: opening,
        purchases: purchases,
        sales: sales,
        inventory: inventory,
        materialsInput: materialsInput,
        finalAmount: finalAmount
      }
      this.sum.push(toAdd)
    },
    filterArticleGroup() {
      //var tempValues = []
      //var tempAmounts = []

      this.resultValues.forEach(element => {
        console.warn(element.articleGroup)
      })
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showAmount: state => state.distributors.showAmount
    }),
    showAmountLocal: {
      get() {
        return this.showAmount
      },
      set(value) {
        this.$store.dispatch('setShowAmount', value)
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    filterFromDate: function() {
      this.loadArticlesStatistics()
    },
    filterToDate: function() {
      this.loadArticlesStatistics()
    },
    category: function() {
      this.init()
    },
    search: function() {
      console.warn(this.$refs.datatable)
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

* >>> .style-1 {
  background-color: #8fc5e1;
}
* >>> .style-1:hover {
  background-color: #8fc5e1 !important;
}
* >>> .style-2 {
  background-color: white !important;
}
</style>
