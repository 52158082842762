<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        outlined
        hide-details
      ></v-text-field>
    </v-card-title>
    <WarningDialog
      ref="warning"
      @warningActionPerformed="warningActionPerformed"
    />
    <v-dialog v-model="warningDialog" width="650">
      <v-card>
        <v-toolbar color="warning" dark>
          <v-icon @click="warningDialog = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center">
            Warnung
          </v-toolbar-title>
        </v-toolbar>
        <v-container class="pa-4">
          <v-row class="mx-2">
            <v-col cols="12">
              Wollen Sie diese Kundengruppe wirklich löschen?
              <v-card-text></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error lighten-1 px-8" @click="deleteItemConfirm()"
                  >LÖSCHEN</v-btn
                >
                <v-btn color="black" text @click="warningDialog = false"
                  >Abbrechen</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="d-flex flex-column align-end"
              cols="colsPerButton"
              v-for="action in actions"
              :key="action.action"
            >
              <v-btn
                outlined
                class="warning--text"
                @click="warningActionPerformed(action.action)"
              >
                <v-icon class="mr-1">{{ action.icon }}</v-icon>
                {{ action.text }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="nonEmptyCustomerGroups"
      :items-per-page="10"
      :search="search"
      item-key="key.kdnr + key.lfdnr"
      class="elevation-1"
      :loading="loading"
      sort-by="order"
      :sort-desc="sortDesc"
      mobile-breakpoint="800"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="showCustomerGroup"
            scrollable
            max-width="500px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click=";(newCustomerGroup = true), show({})"
                :disabled="allCustomerGroupsUsed"
              >
                Neue Kd.-Gruppen-Zuordnung
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="mx-0 px-0 my-0 py-0">
                <v-toolbar
                  color="primary"
                  class="mx-0 px-0 my-0 py-0"
                  fixed
                  top
                  dark
                >
                  <v-icon @click="cancel(), close()">mdi-close</v-icon>

                  <v-toolbar-title
                    class="headline flex text-center text-h6 font-weight-regular"
                  >
                    {{
                      newCustomerGroup
                        ? 'Kundengruppe zuordnen'
                        : editDialogTitle
                    }}
                  </v-toolbar-title>
                </v-toolbar>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row class="py-4 mt-3">
                    <v-col class="pa-0 pr-3" cols="3">
                      <v-row class="pt-3 d-flex justify-center">
                        <span>Reihung</span>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <p class="mt-1 text-subtitle-1">
                          {{
                            activeCustomerGroup.order != null
                              ? activeCustomerGroup.order + 1
                              : '-'
                          }}
                        </p>
                      </v-row>
                    </v-col>
                    <v-col class="pa-0 pr-1 pt-1" cols="9">
                      <v-select
                        label="Kundengruppe"
                        :items="unusedAvailableCustomerGroups"
                        item-text="bezeichnung"
                        v-model="activeCustomerGroup.customerGroup"
                        outlined
                        return-object
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  @click="
                    newCustomerGroup === true
                      ? save(activeCustomerGroup)
                      : update(activeCustomerGroup)
                  "
                  :disabled="activeCustomerGroup.customerGroup == null"
                >
                  Speichern
                </v-btn>
                <v-btn color="error" text @click="cancel(), close()">
                  Abbrechen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- <template v-slot:item.hauptansprechpartner="{ item }">
        <v-simple-checkbox
          v-model="item.hauptansprechpartner"
          disabled
        ></v-simple-checkbox>
      </template> -->

      <template v-slot:item.order="{ item }">
        {{ item.order != null ? item.order + 1 : '-' }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          fab
          small
          text
          elevation="2"
          class="mr-2 col-xl-5"
          @click=";(newCustomerGroup = false), show(item)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          text
          elevation="2"
          color="red"
          class="mr-2 col-xl-5"
          @click=";(deleteCustomerGroup = item), (warningDialog = true)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data> Keine Kundengruppen zugeordnet </template>
    </v-data-table>
    <template>
      <ErrorDialog
        :failMessage="failMessage"
        :opened="errorDialog"
        @closeDialog="errorDialog = false"
      />
    </template>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import WarningDialog from '@/components/core/WarningDialog.vue'

export default {
  components: {
    ErrorDialog,
    WarningDialog
  },
  props: {
    lfdnr: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    search: null,
    deleteCustomerGroupDialog: false,
    sortDesc: false,
    loading: false,
    deleteCustomerGroup: null,
    newCustomerGroup: false,
    showCustomerGroup: false,
    warningDialog: false,
    warnMessage: 'Wollen Sie diese Kundengruppe wirklich löschen?',
    actions: [],
    colsPerButton: 0,
    additionalData: [],
    headers: [
      {
        text: 'Reihung',
        align: 'start',
        value: 'order'
      },
      {
        text: 'Kundengruppe',
        value: 'customerGroup.bezeichnung'
      },
      {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12em'
      }
    ],

    activeCustomerGroup: [],
    oldCustomerGroup: null,
    editDialogTitle: '',
    failMessage: '',
    errorDialog: false
  }),
  computed: {
    ...mapState({
      customerGroups: state => state.crmCustomerGroups.customerGroups,
      availableCustomerGroups: state =>
        state.crmCustomerGroups.availableCustomerGroups
    }),
    nonEmptyCustomerGroups() {
      return this.customerGroups.filter(cg => cg.customerGroup != null)
    },

    unusedAvailableCustomerGroups() {
      return this.availableCustomerGroups.filter(
        a =>
          false ==
          this.customerGroups
            .map(cg => cg.customerGroup)
            .some(
              cg =>
                cg != null &&
                cg.bezeichnung == a.bezeichnung &&
                cg.key?.code == a.key?.code &&
                cg.key?.firma == a.key?.firma
            )
      )
    },
    allCustomerGroupsUsed() {
      return this.unusedAvailableCustomerGroups.length == 0
    }
  },
  methods: {
    init() {
      this.loadCustomerGroups()
      this.loadAvailableCustomerGroups()
    },
    loadCustomerGroups() {
      this.$store.dispatch('loadCustomerGroups', { lfdnr: this.lfdnr })
    },
    open(warnMessage, actions, additionalData = []) {
      console.log('open')
      this.warnMessage = warnMessage
      this.actions = actions
      this.warningDialog = true
      this.colsPerButton = Math.floor(12 / actions.length)
      this.additionalData = additionalData
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action)
      this.warningDialog = false
    },

    loadAvailableCustomerGroups() {
      this.$store.dispatch('loadAvailableCustomerGroups')
    },
    deleteItemConfirm() {
      this.remove(this.deleteCustomerGroup)
      this.warningDialog = false
      this.deleteCustomerGroup = null
    },

    show(item) {
      if (this.newCustomerGroup) {
        const highestOrder = Math.max(
          ...this.customerGroups.map(cg => cg.order)
        )
        this.activeCustomerGroup = {
          order:
            highestOrder == -Infinity || isNaN(highestOrder)
              ? 0
              : highestOrder + 1,
          lfdnr: this.lfdnr
        }
      } else {
        this.activeCustomerGroup = { ...item, customerGroup: null }
        this.oldCustomerGroup = { ...item }

        this.editDialogTitle = this.getEditDialogTitle(item)
      }

      this.showCustomerGroup = true
    },

    save(toSave) {
      this.loading = true
      this.$store
        .dispatch('saveCustomerGroup', {
          lfdnr: this.lfdnr,
          customerGroup: toSave
        })
        .then(() => {
          this.showCustomerGroup = false
        })
        .catch(err => {
          console.error(err)
          this.failMessage =
            'Während dem Speichern der Kundengruppenzuordnung ist ein Fehler aufgetreten!'
          this.errorDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    update(toUpdate) {
      this.loading = true
      this.$store
        .dispatch('updateCustomerGroupRelation', {
          lfdnr: this.lfdnr,
          oldCode: this.oldCustomerGroup?.customerGroup?.key?.code,
          customerGroup: toUpdate
        })
        .then(() => {
          this.showCustomerGroup = false
        })
        .catch(err => {
          console.error(err)
          this.failMessage =
            'Während dem Update einer Kundengruppenzuordnung ist ein Fehler aufgetreten!'
          this.errorDialog = true
        })
        .finally((this.loading = false))
    },

    cancel() {
      this.activeCustomerGroup = { ...this.oldCustomerGroup }
    },

    close() {
      this.showCustomerGroup = false
    },

    remove(item) {
      this.$store
        .dispatch('removeCustomerGroupRelation', {
          lfdnr: this.lfdnr,
          customerGroup: item
        })
        .then(() => {
          this.loading = false
          this.deleteCustomerGroupDialog = false
        })
    },
    deleteCustomerGroupConformationMsg(item) {
      let customerGroupName = item.customerGroup.bezeichnung
      if (customerGroupName >= 15)
        customerGroupName = customerGroupName.substring(0, 13) + '...'
      return `Wollen Sie die Kundengruppe "${customerGroupName}" wirklich löschen?`
    },
    getEditDialogTitle(item) {
      let desc = item.customerGroup.bezeichnung
      if (desc.length >= 15) desc = desc.substring(0, 13) + '...'
      return `Kundengruppe "${desc}" bearbeiten`
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    }
  }
}
</script>

<style></style>
