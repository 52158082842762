<template>
  <v-card v-if="visible">
    <br />
    <h5 class="text-h5 text-center mt-3">Umsatz pro Artikelmerkmal</h5>
    <p class="caption text-center">{{ timePeriod }}</p>

    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        outlined
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="articleArticleCharacteristicsHeader"
      :items="articleCharacteristicsTable"
      :search="search"
      :loading="loading"
      :item-class="itemRowBackground"
      show-expand
    >
      <template v-slot:item.revenue="{ item }">
        <span>{{ formatEuro(item.revenue) }}</span>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="py-3">
          <h5 class="mt-1">Allgemeine Eigenschaften:</h5>
          <hr />
          <v-simple-table class="mt-2">
            <thead>
              <tr>
                <th class="text-left">Eigenschaft</th>
                <th class="text-left">Wert</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Artikelnummer</td>
                <td>{{ item.articleNumber }}</td>
              </tr>
              <tr>
                <td>Artikelsachmerkmal EAN</td>
                <td>{{ item.articlePropertyEAN }}</td>
              </tr>
              <tr>
                <td>Artikelsachmerkmal Lfdnr.</td>
                <td>{{ item.articlePropertyNumber }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <h5 class="mt-7">Sachmerkmal-Eigenschaften:</h5>
          <hr />
          <v-simple-table class="mt-2">
            <thead>
              <tr>
                <th class="text-left">Nummer</th>
                <th class="text-left">Bezeichnung</th>
                <th class="text-left">Wert</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{{ item.articleProperty_1_description }}</td>
                <td>
                  {{
                    item.articleProperty_1_value == null
                      ? '-'
                      : item.articleProperty_1_value
                  }}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>{{ item.articleProperty_2_description }}</td>
                <td>
                  {{
                    item.articleProperty_2_value == null
                      ? '-'
                      : item.articleProperty_2_value
                  }}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>{{ item.articleProperty_3_description }}</td>
                <td>
                  {{
                    item.articleProperty_3_value == null
                      ? '-'
                      : item.articleProperty_3_value
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import currencyFormatService from '@/services/formatters/currencyFormatService.js'

export default {
  props: ['visible', 'timePeriod', 'articleCharacteristicsTable', 'loading'],
  data: () => ({
    articleArticleCharacteristicsHeader: [
      {
        text: 'Artikel Name',
        value: 'articleDescription'
      },
      // { text: 'Merkmal 1', value: 'sm1' },
      // { text: 'Merkmal 2', value: 'sm2' },
      // { text: 'Merkmal 3', value: 'sm3' },
      { text: 'Menge', value: 'amount', align: 'end' },
      { text: 'Umsatz', value: 'revenue', align: 'end' }
    ],
    search: ''
  }),
  methods: {
    itemRowBackground: function(item) {
      if (
        item.articleType == this.articleTypeTemp ||
        item.category == this.articleCategoryTemp ||
        item.articleNumber == this.articleArticleTemp
      ) {
        return 'style-1'
      } else {
        return 'style-2'
      }
    },
    formatEuro(value) {
      return currencyFormatService.formatEuro(value)
    }
  }
}
</script>

<style></style>
