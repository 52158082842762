<template>
  <div class="informationTabAlign">
    <v-form>
      <!-- TODO: add v-models--><!-- TODO: expand address models-->
      <v-row>
        <v-col cols="12" lg="8" xl="8" md="8">
          <v-text-field
            outlined
            label="Krankenkasse"
            prepend-inner-icon="mdi-hospital-building"
            v-model="address.patient.healthInsuranceCompanyName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            outlined
            label="SVNR"
            append-icon="mdi-card-account-details"
            v-model="address.patient.svnr"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            outlined
            label="SVNR Dat."
            v-model="address.patient.svnrDat"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" xl="4">
          <v-checkbox
            label="Kassenmitarbeiter"
            prepend-icon="mdi-cash-register"
            v-model="address.employee.cashier"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="4" md="4" xl="4">
          <v-checkbox
            label="BDE-Mitarbeiter"
            prepend-icon="mdi-database-edit"
            v-model="address.employee.bdeEmployee"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="4" md="4" xl="4">
          <v-checkbox
            label="Externer Mitarbeiter"
            prepend-icon="mdi-account-arrow-left"
            v-model="address.employee.externalEmployee"
            disabled
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            label="Personalnummer"
            append-icon="mdi-numeric"
            v-model="address.employee.staffId"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            label="Ausweisnummer"
            append-icon="mdi-id-card"
            v-model="address.employee.idNumber"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" xl="4" lg="4">
          <v-menu
            ref="menu_enter"
            :close-on-content-click="false"
            :return-value.sync="date_enter"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                label="Eintrittsdatum"
                append-icon="mdi-calendar-import"
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="address.employee.entryDate"
                disabled
              ></v-text-field>
            </template>
            <v-date-picker type="date" color="primary" no-title scrollable>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" xl="4" lg="4">
          <v-menu
            ref="menu_exit"
            :close-on-content-click="false"
            :return-value.sync="date_exit"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                label="Austrittsdatum"
                append-icon="mdi-calendar-export"
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="address.employee.exitDate"
                disabled
              ></v-text-field>
            </template>
            <v-date-picker type="date" color="primary" no-title scrollable>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-text-field
            prepend-inner-icon="mdi-account"
            label="Loginname"
            outlined
            clearable
            v-model="address.employee.userId"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            clearable
            prepend-inner-icon="mdi-warehouse"
            label="Lager"
            v-model="address.employee.warehouseName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addressService from '@/services/crm/addressService.js'
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson
    })
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    address: null
  }),
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response)
        this.address = response.data
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    }
  }
}
</script>

<style>
.informationTabAlign {
  margin: 4vh 2vw;
}
</style>
