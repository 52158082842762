<template>
  <v-container id="toScroll"
    ><SiteHeaderComponent :header="address.name" />
    <div class="d-flex justify-end"></div>
    <div>
      <TimeFilter ref="timeFilter" />
    </div>
    <v-bottom-navigation
      fixed
      horizontal
      class="ml-10 pb-2 pt-1"
      scroll-threshold="500"
      hide-on-scroll
    >
      <div style="text-align: center;">
        <span>
          <b>{{ address.name }}</b>
        </span>
        <br />
        <span>
          <b>{{ $store.state.statisticsTimeFilter.fromDate }}</b> bis
          <b>{{ $store.state.statisticsTimeFilter.toDate }}</b>
        </span>
      </div>
    </v-bottom-navigation>
    <v-divider></v-divider>

    <div id="scroll-threshold-example">
      <div id="chart" class="mt-4">
        <div class="mx-auto mb-12">
          <v-switch
            class="ml-4"
            v-model="showAmountLocal"
            label="Werte-/Mengendarstellung"
          />
          <v-data-table
            :headers="headersSummary"
            :items="showAmount === true ? dataSummary : dataSummaryValues"
            :items-per-page="5"
            :search="search"
            :loading="loading"
            sort-by="description"
            item-key="code"
            :sort-desc="sortDesc"
            mobile-breakpoint="800"
          >
            <template
              v-for="header in headersSummary.filter(
                header =>
                  header.value !== 'description' && header.value !== 'none'
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              <span v-bind:key="header.text">{{
                formatArticleSummaryTableValue(value)
              }}</span>
            </template>
          </v-data-table>
        </div>
      </div>

      <div class="mx-auto mb-12">
        <v-switch
          class="ml-4"
          v-model="showAmountLocal"
          label="Werte-/Mengendarstellung"
        />
        <v-data-table
          :headers="headers"
          :items="
            showAmount === true ? articleTypesAmounts : articleTypesValues
          "
          :items-per-page="5"
          :search="search"
          item-key="code"
          class="datatable-row-pointer"
          :loading="loading"
          sort-by="description"
          :sort-desc="sortDesc"
          mobile-breakpoint="800"
          @click:row="openArticleType"
          :item-class="itemRowBackground2"
          ><template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              outlined
              hide-details
              dense
            ></v-text-field>
          </template>
          <template v-slot:no-data>
            Keine Artikeltypen in diesem Zeitraum vorhanden.
          </template>

          <template
            v-for="header in headers.filter(
              header => header.value !== 'description'
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            <span v-bind:key="header.text">{{
              formatArticleTypesTableValue(value)
            }}</span>
          </template>
        </v-data-table>
      </div>

      <div v-show="articleCategorysShow">
        <ArticleCategories
          v-bind:lfdnr="this.lfdnr"
          v-bind:articleType="this.articleType"
        />
        <router-view></router-view>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import addressService from '@/services/crm/addressService.js'
import distributorService from '@/services/statistics/distributorService.js'
import currencyFormatService from '@/services/formatters/currencyFormatService.js'
import ArticleCategories from '@/components/statistics/EkRevenueStatistics/ArticleCategories.vue'

import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import TimeFilter from '@/components/statistics/TimeFilter.vue'

export default {
  components: {
    SiteHeaderComponent,
    TimeFilter,
    ArticleCategories
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    summaryChartDataValues: [],
    summaryChartDataAmounts: [],
    summaryChartOptions: {},
    articleTypesAmounts: [],
    articleTypesValues: [],
    articleType: '0',
    headersSummary: [
      { text: 'Gesamt', value: 'none', align: 'left' },
      { text: 'Anfangsbestand', value: 'opening', align: 'center' },
      { text: 'Einkauf', value: 'purchases', align: 'center' },
      { text: 'Verkauf', value: 'sales', align: 'center' },
      { text: 'Inventur', value: 'inventory', align: 'center' },
      {
        text: 'Materialverbrauch',
        value: 'materialsInput',
        align: 'center'
      },
      { text: 'Endbestand', value: 'final', align: 'center' }
    ],
    dataSummary: [],
    dataSummaryValues: [],
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    articleCodeTemp: '',
    articleCategorysShow: false,
    articlesShow: false,
    headers: [
      {
        text: 'Artikeltype',
        value: 'description',
        align: 'start'
      },
      {
        text: 'Anfangsbestand',
        value: 'opening',
        align: 'end'
      },
      {
        text: 'Einkäufe',
        value: 'purchases',
        align: 'end'
      },
      {
        text: 'Verkäufe',
        value: 'sales',
        align: 'end'
      },
      {
        text: 'Inventur',
        value: 'inventory',
        align: 'end'
      },
      {
        text: 'Materialverbrauch',
        value: 'materialsInput',
        align: 'end'
      },
      {
        text: 'Endbestand',
        value: 'finalAmount',
        align: 'end'
      }
    ],
    // Chart
    series: [
      {
        name: 'Betrag',
        data: []
      }
    ]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          formatter: value => {
            return this.formatSummaryChartLabel(value)
          }
        },

        xaxis: {
          categories: [''],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            formatter: value => {
              return this.formatSummaryChartLabel(value)
            }
          }
        },
        title: {
          text: 'Gesamtstatistik',
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        colors: ['#FF6633']
      }
    },
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data
      })
      this.loadArticleTypeStatistics()
      document.getElementById('toScroll').scrollIntoView({ behavior: 'smooth' })

      this.summaryChartOptions = this.chartOptions()
      this.openArticleType(this.item)
    },
    loadArticleTypeStatistics() {
      this.loading = true
      distributorService
        .getArticleTypesStatistics(
          this.lfdnr,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          // Filter out zero-lines
          const filteredArticleTypeValues = []
          const filteredArticleTypeAmounts = []
          response.data.forEach(articleType => {
            if (false === this.isZeroLine(articleType.valueModel)) {
              articleType.valueModel['description'] = articleType.description
              articleType.valueModel['code'] = articleType.code
              filteredArticleTypeValues.push(articleType.valueModel)
            }
            if (false === this.isZeroLine(articleType.amountModel)) {
              articleType.amountModel['description'] = articleType.description
              articleType.amountModel['code'] = articleType.code
              filteredArticleTypeAmounts.push(articleType.amountModel)
            }
          })

          this.articleTypesValues = filteredArticleTypeValues
          this.articleTypesAmounts = filteredArticleTypeAmounts
          this.loading = false
        })

      distributorService
        .getArticleTypesSummaryStatistics(
          this.lfdnr,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          var obj = response.data
          this.dataSummary = []
          this.dataSummaryValues = []
          this.dataSummary.push({
            opening: obj.openingAmount,
            purchases: obj.purchasesAmount,
            sales: obj.salesAmount,
            inventory: obj.inventoryAmount,
            materialsInput: obj.materialsInputAmount,
            final: obj.finalAmount
          })
          this.dataSummaryValues.push({
            opening: obj.openingValue,
            purchases: obj.purchasesValue,
            sales: obj.salesValue,
            inventory: obj.inventoryValue,
            materialsInput: obj.materialsInputValue,
            final: obj.finalValue
          })
        })
    },
    openArticleType(item) {
      if (this.articleCodeTemp == item.code) {
        this.articleCodeTemp = ''
        this.articleCategorysShow = false
        this.articlesShow = false
      } else {
        this.articleCodeTemp = item.code
        this.articleType = item.code
        //console.log(this.articleType)
        this.articleCategorysShow = true
      }
      // statisticsDistributorArticleCategories
      /*this.$router.push({
        name: 'statisticsDistributorArticleCategories',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: { lfdnr: this.lfdnr, articleType: item.code }
      })*/
    },

    itemRowBackground2: function(item) {
      console.log('code: ' + item.code + 'artikelcodetemp: ' + this.articleType)
      if (item.code == this.articleCodeTemp) {
        return 'style-1'
      } else {
        return 'style-2'
      }
    },
    formatArticleTypesTableValue(value) {
      if (this.showAmount === true) return value == 0 ? '' : value + ''
      else return value == 0 ? '' : currencyFormatService.formatEuro(value)
    },
    formatArticleSummaryTableValue(value) {
      if (this.showAmount) {
        return value == 0 ? '' : value + ''
      } else return value == 0 ? '' : currencyFormatService.formatEuro(value)
    },
    isZeroLine(model) {
      return (
        model.opening === 0 &&
        model.purchases === 0 &&
        model.sales === 0 &&
        model.inventory === 0 &&
        model.materialsInput === 0 &&
        model.finalAmount === 0
      )
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showAmount: state => state.distributors.showAmount
    }),
    showAmountLocal: {
      get() {
        return this.showAmount
      },
      set(value) {
        this.$store.dispatch('setShowAmount', value)
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    },
    filterFromDate: function() {
      this.loadArticleTypeStatistics()
    },
    filterToDate: function() {
      this.loadArticleTypeStatistics()
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
* >>> .style-1 {
  background-color: #8fc5e1;
}
* >>> .style-1:hover {
  background-color: #8fc5e1 !important;
}
* >>> .style-2 {
  background-color: white !important;
}
</style>
