<template>
  <div class="alignInformationTab">
    <v-form>
      <v-row>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            outlined
            label="Vertragsnummer"
            append-icon="mdi-numeric"
            v-model="address.company.contractNumber"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4">
          <v-text-field
            outlined
            label="UID"
            append-icon="mdi-identifier"
            v-model="address.company.uid"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-text-field
            outlined
            label="Kundengruppe"
            disabled
            v-model="address.company.customerGroupName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            outlined
            label="Verband"
            prepend-inner-icon="mdi-account-group"
            v-model="address.company.associationName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            outlined
            label="Branche"
            prepend-inner-icon="mdi-account-hard-hat"
            v-model="address.company.industrialSectorsName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <h2 class="overline font-weight-black mt-3">Lieferung und Bezahlung</h2>
      <v-divider />
      <v-row class="mt-3">
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            prepend-inner-icon="mdi-truck"
            label="Versandart"
            v-model="address.company.deliveryAndPayment.shippingMethodName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            prepend-inner-icon="mdi-truck-alert"
            label="Lieferbedingung"
            v-model="address.company.deliveryAndPayment.shippingConditionName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            prepend-inner-icon="mdi-cash"
            label="Zahlungsbedingung"
            v-model="address.company.deliveryAndPayment.paymentConditionName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            label="Verkäufer"
            prepend-inner-icon="mdi-account"
            v-model="address.company.deliveryAndPayment.salesPersonName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            suffix="Tage"
            outlined
            label="Sammelliefers. Int."
            v-model="
              address.company.deliveryAndPayment.collectiveDeliveryNoteInterval
            "
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            suffix="Tage"
            outlined
            label="Sammelr. Int."
            v-model="
              address.company.deliveryAndPayment.collectiveInvoiceInterval
            "
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-text-field
            outlined
            label="Lager"
            prepend-inner-icon="mdi-warehouse"
            v-model="address.company.deliveryAndPayment.warehouseName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="5" xl="5" md="5">
          <v-text-field
            label="Rabatt Typ"
            outlined
            prepend-inner-icon="mdi-file-percent"
            v-model="address.company.deliveryAndPayment.discountType"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            outlined
            suffix="%"
            label="Rabattausmaß"
            v-model="address.company.deliveryAndPayment.discount"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-checkbox
            label="Verband ist Rechnungsadresse"
            v-model="
              address.company.deliveryAndPayment.associationIsInvoiceAddress
            "
            disabled
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="8" xl="8" md="8">
          <v-text-field
            outlined
            label="Fibugruppe"
            prepend-inner-icon="mdi-account-group"
            v-model="address.company.deliveryAndPayment.fibuGroup"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addressService from '@/services/crm/addressService.js'
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson
    })
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    address: null
  }),
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response)
        this.address = response.data
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    }
  }
}
</script>

<style>
.informationTabAlign {
  margin: 4vh 2vw;
}
</style>
