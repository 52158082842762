<template>
  <router-link :to="destination" :target="target">
    <v-btn icon outlined class="mr-2 col-xl-5">
      <v-icon small color="primary"> mdi-eye </v-icon>
    </v-btn>
  </router-link>
</template>
<script>
export default {
  props: {
    destination: {
      type: Object,
      require: true
    },
    newTab: {
      type: Boolean,
      require: true
    }
  },
  computed: {
    target() {
      let targetString = ''
      if (this.newTab) {
        targetString = '_blank'
      }
      return targetString
    }
  }
}
</script>
