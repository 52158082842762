<template>
  <div class="alignInformationTab">
    <v-form>
      <v-row>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-text-field
            outlined
            label="Kundennummer"
            append-icon="mdi-numeric"
            v-model="address.supplier.kdNr"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-text-field
            outlined
            label="UID"
            append-icon="mdi-identifier"
            v-model="address.supplier.uid"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" xl="4">
          <v-text-field
            outlined
            label="Lieferantengruppe"
            v-model="address.supplier.supplierGroupName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" xl="4">
          <v-text-field
            outlined
            label="Verband"
            prepend-inner-icon="mdi-account-group"
            v-model="address.supplier.associationName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6">
          <v-text-field
            outlined
            label="Branche"
            prepend-inner-icon="mdi-account-hard-hat"
            v-model="address.supplier.industrialSectorName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" xl="6">
          <v-text-field
            outlined
            label="Fibugruppe"
            prepend-inner-icon="mdi-account-group"
            v-model="address.supplier.fibuGroupName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <h2 class="overline font-weight-black mt-3">Lieferung und Bestellung</h2>
      <v-divider />

      <v-row class="mt-3">
        <v-col cols="12" lg="3" md="3" xl="3">
          <v-text-field
            outlined
            label="Versandart"
            prepend-inner-icon="mdi-truck"
            v-model="address.supplier.deliveryAndOrder.shippingMethodName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" xl="3">
          <v-text-field
            outlined
            label="Lieferbedingung"
            prepend-inner-icon="mdi-truck-alert"
            v-model="address.supplier.deliveryAndOrder.shippingConditionName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="PA auf Anfrage"
            v-model="address.supplier.deliveryAndOrder.priceOnDemand"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="PA auf Bestellung"
            v-model="address.supplier.deliveryAndOrder.priceOnOrder"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Rechnungssperre"
            v-model="address.supplier.deliveryAndOrder.invoiceBlocking"
            disabled
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            suffix="Tage"
            outlined
            label="Sammelliefers. Int."
            v-model="
              address.supplier.deliveryAndOrder.collectiveDeliveryNoteInterval
            "
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            suffix="Tage"
            outlined
            label="Sammelr. Int."
            v-model="
              address.supplier.deliveryAndOrder.collectiveInvoiceInterval
            "
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Filialdirektbel."
            v-model="address.supplier.deliveryAndOrder.directBranchShipping"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Kundendirektbel."
            v-model="address.supplier.deliveryAndOrder.directCustomerDelivery"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="2" md="2" xl="2">
          <v-checkbox
            label="Verband Mwst."
            v-model="address.supplier.deliveryAndOrder.associationVat"
            disabled
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-text-field
            outlined
            label="Kreditorennummer"
            append-icon="mdi-numeric"
            v-model="address.supplier.deliveryAndOrder.creditorNumber"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" xl="3">
          <v-text-field
            outlined
            label="Anbruchpackungen"
            prepend-inner-icon="mdi-package-variant"
            disabled
            v-model="address.supplier.deliveryAndOrder.openedPacks"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" xl="3">
          <v-text-field
            outlined
            label="Dezimalstellen"
            append-icon="mdi-decimal-comma"
            disabled
            v-model="address.supplier.deliveryAndOrder.decimalPlaces"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" xl="3"
          ><v-text-field
            outlined
            label="Mindestbestellwert"
            append-icon="mdi-currency-eur"
            v-model="address.supplier.deliveryAndOrder.minimumOrderValue"
            disabled
          >
          </v-text-field
        ></v-col>
      </v-row>

      <h2 class="overline font-weight-black mt-3">Bezahlung</h2>
      <v-divider />

      <v-row class="mt-3">
        <v-col cols="12" lg="6" md="6" xl="6">
          <v-text-field
            outlined
            prepend-inner-icon="mdi-cash"
            item-text="description"
            item-value="id"
            label="Zahlungsbedingung"
            disabled
            v-model="address.supplier.payment.paymentConditionsName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" xl="6">
          <v-text-field
            outlined
            prepend-inner-icon="mdi-cash"
            item-text="description"
            item-value="id"
            label="Zahlungsart"
            v-model="address.supplier.payment.paymentTypesName"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6">
          <v-text-field
            outlined
            append-icon="mdi-credit-card"
            label="IBAN"
            v-model="address.supplier.payment.iban"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" xl="6">
          <v-text-field
            outlined
            append-icon="mdi-numeric"
            label="BIC"
            v-model="address.supplier.payment.bic"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3" xl="3" md="3">
          <v-checkbox
            label="Sperre Zahlungsvorschlag"
            prepend-icon="mdi-lock"
            v-model="address.supplier.payment.lockPaymentProposal"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" lg="3" xl="3" md="3">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              label="Einzugsermächtigungsdatum"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
          <v-text-field
            outlined
            label="Rabattart"
            prepend-inner-icon="mdi-percent"
            v-model="address.supplier.payment.discountType"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="2" lg="2" xl="2" md="2">
          <v-text-field
            outlined
            label="Rabattausmaß"
            suffix="%"
            v-model="address.supplier.payment.discount"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addressService from '@/services/crm/addressService.js'
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,

      options: state => state.crmAddresses.addressOptions
    })
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    address: null
  }),
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response)
        this.address = response.data
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    }
  }
}
</script>

<style>
.alignInformationTab {
  margin: 4vh 2vw;
}
</style>
