<template>
  <div class="informationTabAlign">
    <v-form>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            outlined
            label="* Krankenkasse"
            prepend-inner-icon="mdi-hospital-building"
            v-model="address.patient.healthInsuranceCompanyName"
            disabled
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2" lg="2" xl="2">
          <v-checkbox
            :label="'Diabetes'"
            v-model="address.patient.diseases.diabetes"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-checkbox
            :label="'Angiopathie'"
            v-model="address.patient.diseases.angiopathie"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-checkbox
            disabled
            :label="'Neuropathie'"
            v-model="address.patient.diseases.neuropathie"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-textarea
            clearable
            rows="2"
            label="Allergien"
            outlined
            prepend-inner-icon="mdi-clipboard-plus-outline"
            v-model="address.patient.diseases.allergies"
            disabled
          >
          </v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            outlined
            label="* Versichertengruppe"
            prepend-inner-icon="mdi-tag-text"
            clearable
            v-model="address.patient.insuredGroupName"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-checkbox
            :label="'Gebührenfrei'"
            v-model="address.patient.feeReady"
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4">
          <v-text-field
            outlined
            append-icon="mdi-calendar-blank"
            label="Regojahr"
            v-model="address.patient.regojahr"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            outlined
            label="* SVNR"
            append-icon="mdi-card-account-details"
            v-model="address.patient.svnr"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            outlined
            label="* SVNR Dat."
            v-model="address.patient.svnrDat"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            outlined
            prepend-inner-icon="mdi-doctor"
            label="* Arzt"
            clearable
            disabled
            v-model="address.patient.familyDoctorName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <!-- TODO: add v-model -->
          <v-text-field
            outlined
            label="HV-Nr."
            disabled
            v-model="address.patient.familyDoctorHvnr"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import addressService from '@/services/crm/addressService.js'
export default {
  data: () => ({
    address: null
  }),
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson
    })
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response)
        this.address = response.data
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    lfdnr() {
      this.init()
    }
  }
}
</script>

<style>
.informationTabAlign {
  margin: 4vh 2vw;
}
</style>
